import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import pageStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {API_ENDPOINT, API_HEADERS} from "../../components/constants";
import axios from "axios";
import selectStyles from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import inputStyles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle";
import regularFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import LoadingSpinner from "../../components/Microcomponents/LoadingSpinner";
import {dangerColor} from "../../assets/jss/material-dashboard-pro-react";


const styles = {
    pageStyles,
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
}
const useStyles = makeStyles(styles);

export default function UserProfile() {
    const [loading, setLoading] = useState(true);
    const [validAvatarUrl, setValidAvatarUrl] = useState(true);

    const [gender, setGender] = useState("");
    const [position, setPosition] = useState("");
    const [avatar_url, setAvatarUrl] = useState("");
    const [office_street, setOfficeStreet] = useState("");
    const [office_zip, setOfficeZip] = useState("");
    const [office_city, setOfficeCity] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setMail] = useState("");
    const [mobile, setMobile] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [xing, setXing] = useState("");
    const [linkedin, setLinkedin] = useState("");

    const isComplete = gender !== "n/a"
        && (first_name && first_name.length > 0)
        && (last_name && last_name.length > 0)
        && (position && position.length > 0)
        // && (validAvatarUrl && avatar_url && avatar_url.length > 0)
        && (phone && phone.length > 0)
        && (mobile && mobile.length > 0)
        && (fax && fax.length > 0)
        && (office_street && office_street.length > 0)
        && (office_zip && office_zip.length > 0)
        && (office_city && office_city.length > 0)

    useEffect(() => {
        axios({
            url: API_ENDPOINT + "/crm/user_profile",
            headers: API_HEADERS
        }).then( r => {
            let _gender = r.data.gender;
            if (_gender === "Herr") _gender = "male";
            if (_gender === "Frau") _gender = "female";
            if (!_gender) _gender = "n/a";
            setGender(_gender);
            setPosition(r.data.position);
            setAvatarUrl(r.data.avatar_url);
            setOfficeStreet(r.data.office_street);
            setOfficeZip(r.data.office_zip);
            setOfficeCity(r.data.office_city);
            setFirstName(r.data.first_name);
            setLastName(r.data.last_name);
            setMail(r.data.email);
            setMobile(r.data.mobile);
            setPhone(r.data.phone);
            setFax(r.data.fax);
            setXing(r.data.xing);
            setLinkedin(r.data.linkedin);
            setLoading(false);
            setValidAvatarUrl(true);
        }).catch( err => console.log(err) )
    }, [])

    const submit = () => {
        setLoading(true);
        const data = {
            gender,
            position,
            avatar_url: validAvatarUrl ? avatar_url : null,
            office_street,
            office_zip,
            office_city,
            first_name,
            last_name,
            email,
            mobile,
            phone,
            fax,
            xing,
            linkedin,
        }
        axios({
            method: 'post',
            url: API_ENDPOINT + "/crm/user_profile/",
            headers: API_HEADERS,
            data
        }).then( r =>{
            setLoading(false)
        }).catch( err => console.log(err))
    }
    const classes = useStyles();
    return (
        <div>
            {loading && <LoadingSpinner/>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <PermIdentity />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Mein Profil bearbeiten
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                {/* CONTACT INFORMATION */}
                                <GridItem xs={12} md={6}>
                                    <GridContainer>

                                        {/* GENDER SELECT */}
                                        <GridItem xs={12}>
                                            <FormControl
                                                fullWidth
                                                className={classes.selectFormControl}
                                            >
                                                <Select
                                                    id="genderSelect"
                                                    style={{ color: dangerColor[0] }}
                                                    MenuProps={{
                                                        className: classes.selectMenu
                                                    }}
                                                    classes={{
                                                        select: gender !== "n/a" ? classes.select : classes.select + " " + "error"
                                                    }}
                                                    value={gender}
                                                    onChange={ e => setGender(e.target.value) }
                                                    inputProps={{
                                                        name: "genderSelect",
                                                        id: "genderSelect",
                                                    }}
                                                >
                                                    <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value="n/a"
                                                        disabled
                                                    >
                                                        Anrede
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value="male"
                                                    >
                                                        Herr
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value="female"
                                                    >
                                                        Frau
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </GridItem>

                                        {/* FIRST NAME */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={first_name && first_name.length > 0}
                                                error={!first_name || (first_name && first_name.length < 1)}
                                                labelText="Vorname"
                                                id="firstNameInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setFirstName(e.target.value),
                                                    value: first_name
                                                }}
                                            />
                                        </GridItem>

                                        {/* LAST NAME */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={last_name && last_name.length > 0}
                                                error={!last_name || (last_name && last_name.length < 1)}
                                                labelText="Nachname"
                                                id="lastNameInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setLastName(e.target.value),
                                                    value: last_name
                                                }}
                                            />
                                        </GridItem>

                                        {/* POSITION */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={position && position.length > 0}
                                                error={!position || (position && position.length < 1)}
                                                labelText="Position"
                                                id="positionInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setPosition(e.target.value),
                                                    value: position
                                                }}
                                            />
                                        </GridItem>

                                        {/* EMAIL */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText="Email"
                                                id="emailInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    type: "email",
                                                    value: email
                                                }}
                                            />
                                        </GridItem>

                                        {/* xing */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText="Xing-Profil"
                                                id="xingInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setXing(e.target.value),
                                                    value: xing
                                                }}
                                            />
                                        </GridItem>

                                        {/* linkedin */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText="LinkedIn-Profil"
                                                id="linkedInInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setLinkedin(e.target.value),
                                                    value: linkedin
                                                }}
                                            />
                                        </GridItem>

                                        {/* avatar url */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText="Link zum Profilbild"
                                                id="avatarInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => {
                                                        setAvatarUrl(e.target.value)
                                                        setValidAvatarUrl(true)
                                                    },
                                                    value: avatar_url
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                {/* ADDRESS */}
                                <GridItem xs={12} md={6}>
                                    <GridContainer>

                                        {/* PHONE */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={phone && phone.length > 0}
                                                error={!phone || (phone && phone.length < 1)}
                                                labelText="Telefon"
                                                id="phoneInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "phone",
                                                    onChange: e => setPhone(e.target.value),
                                                    value: phone
                                                }}
                                            />
                                        </GridItem>

                                        {/* MOBILE */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={mobile && mobile.length > 0}
                                                error={!mobile || (mobile && mobile.length < 1)}
                                                labelText="Mobil"
                                                id="mobileInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "mobile",
                                                    onChange: e => setMobile(e.target.value),
                                                    value: mobile
                                                }}
                                            />
                                        </GridItem>

                                        {/* FAX */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={fax && fax.length > 0}
                                                error={!fax || (fax && fax.length < 1)}
                                                labelText="Fax"
                                                id="faxInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    type: "fax",
                                                    onChange: e => setFax(e.target.value),
                                                    value: fax
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        {/* STREET */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={office_street && office_street.length > 0}
                                                error={!office_street || (office_street && office_street.length < 1)}
                                                labelText="Straße"
                                                id="streetInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setOfficeStreet(e.target.value),
                                                    value: office_street
                                                }}
                                            />
                                        </GridItem>

                                        {/* ZIP CODE */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={office_zip && office_zip.length > 0}
                                                error={!office_zip || (office_zip && office_zip.length < 1)}
                                                labelText="PLZ"
                                                id="zipCodeInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setOfficeZip(e.target.value),
                                                    value: office_zip
                                                }}
                                            />
                                        </GridItem>

                                        {/* ZIP CODE */}
                                        <GridItem xs={12}>
                                            <CustomInput
                                                success={office_city && office_city.length > 0}
                                                error={!office_city || (office_city && office_city.length < 1)}
                                                labelText="Stadt"
                                                id="consultant-cityInput"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: e => setOfficeCity(e.target.value),
                                                    value: office_city
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                            {isComplete && <Button color="primary" className={classes.updateProfileButton} onClick={ () => submit() }>
                                Profil aktualisieren
                            </Button>}
                            <Clearfix />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card profile>
                        <CardAvatar profile>
                            <a href="#" onClick={e => e.preventDefault()}>
                                { validAvatarUrl && avatar_url && avatar_url.length > 0
                                    ? <img src={avatar_url} alt="Profilbild" onError={ () => setValidAvatarUrl(false) }/>
                                    : <img src="https://via.placeholder.com/350?text=Bild%20fehlt" alt="Avatar placeholder"/>
                                }
                            </a>
                        </CardAvatar>
                        <CardBody profile>
                            <h6 className={classes.cardCategory}>{position}</h6>
                            <h4 className={classes.cardTitle}>{first_name} {last_name}</h4>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
