import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {emailRex} from "../../../../components/constants";

const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
};


class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    sendState() {
        return this.state;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
    }

    isValidated(){
        return true
    }

    render() {
        const {
        } = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        GULPi
                    </h4>
                    <p className={classes.infoDescription}>
                        Bitte prüfe deine eigenen Angaben
                    </p>
                </GridItem>

                {/* CONTACT INFORMATION */}
                <GridItem xs={12} md={5}>
                    <GridContainer>
                    </GridContainer>
                </GridItem>

                {/* ADDRESS */}
                <GridItem xs={12} md={5}>
                    <GridContainer>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

Step3.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step3);
