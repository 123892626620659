import React from 'react';
import LoadingSpinner from "../../components/Microcomponents/LoadingSpinner";


export default class Authentication extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount(){
        let params = new URLSearchParams(window.location.search);
        let token = params.get('token');
        localStorage.setItem('userToken', token);
        window.location.href = "/";
    }
    render(){
        return <div>
            <LoadingSpinner/>
        </div>
    }
}