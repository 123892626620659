import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";


export default function LoadingSpinner({message}) {
    const style = {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999999,
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#990000",
        color: "rgba(255,255,255,.75)",
}
    return <div style={style}>
        {message
            ? <div dangerouslySetInnerHTML={{ __html: message }}/>
            :<div>
                <ScaleLoader
                    height={40}
                    width={9}
                    radius={2}
                    margin={8}
                    color="#fff"
                />
                <p style={{ fontWeight: 700, size: "2rem" }}>BITTE WARTEN...</p>
            </div>
        }
    </div>
}