import FormatQuote from "@material-ui/icons/FormatQuote";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import Card from "../../components/Card/Card";
import React from "react";

export function BetaLabExplaination({classes}){
    return <Card testimonial>
        <div className={classes.testimonialIcon}>
            <FormatQuote />
        </div>
        <CardBody>
            <h5 className={classes.cardTestimonialDescription}>
                <p><strong>Was das BetaLab ist?</strong></p>

                <p>Wir entwickeln Ideen und stellen sie auf dem schnellstmöglichen Weg auf den Prüfstand. Durch erste Prototypen oder minimal funktionsfähige Produkte sehen wir, ob sich der Ansatz beweist und weiterverfolgt werden sollte.</p>

                <p>Die behandelten Ideen haben einen Business-Bezug und beachten geltendes Recht, richten sich jedoch nicht nach Wirtschaftlichkeit, Prozessvorschriften, Unternehmens-Kennzahlen oder -Strategien. Sie sind verschiedenster Art, müssen nicht freigegeben werden und unterliegen keiner Zielvorgabe.</p>

                <p>Um schnell und unabhängig operieren zu können, bedienen wir uns für die Umsetzung der Ideen soweit wie möglich unserer eigenen Ressourcen sowie der Bord-Mittel bei GULP und binden KollegInnen in nur minimalen Maße ein.</p>

                <p>Hat sich eine Idee als nicht realisierbar erwiesen, generieren wir weitere Ideen. Erweist sich eine Idee als wertvoll, wird sie durch T&I weitergetragen.</p>

                <p>Wir arbeiten autark, agil, flexibel und ohne Hierarchien.</p>
            </h5>
        </CardBody>
        <CardFooter testimonial>
            <h4 className={classes.cardTitle}>Daniela Kluge</h4>
            <h6 className={classes.cardCategory}>@DANIELAKLUGE</h6>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="https://www.gulp.de/images/mitarbeiter/danielar/dk_150x100.jpg" alt="..." className={classes.avatar}/>
            </div>
        </CardFooter>
    </Card>
}