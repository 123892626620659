import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import inputStyles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle";
import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";

var defaultPickerStyles = {
    ...dashboardStyle,
    ...loginPageStyle,
    ...inputStyles,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useDefaultPickerStyles = makeStyles(defaultPickerStyles);

export default function DefaultApplicationPicker({onSubmit}){
    const [applicationId, setApplicationId] = useState("");
    const classes = useDefaultPickerStyles();
    let validApplicationId = false;
    if (applicationId.length === 18) validApplicationId = true;
    if (applicationId.substring(0,82) === "https://gulp-switch.lightning.force.com/lightning/r/cxsrec__cxsJob_application__c/") validApplicationId = true;

    const submit = () => {
        let _applicationId = applicationId
        if (applicationId.substring(0,82) === "https://gulp-switch.lightning.force.com/lightning/r/cxsrec__cxsJob_application__c/"){
            _applicationId = applicationId.substring(80,100)
        };
        onSubmit();
        window.open(window.location.pathname + "?applicationId=" + _applicationId, "_self");
    }
    return (
        <Card>
            <CardHeader>
                <h4 className={classes.cardTitle}>Bewerbungs-ID erforderlich</h4>
                <p>Bitte gebe die Salesforce-ID Deiner Bewerbung ein.</p>
                <p>Alternativ kannst Du auch den Link zu deiner Bewerbung in Salesforce eingeben.</p>
                <p><strong>Beispiele:</strong></p>
                <p>Salesforce-ID: <br/><code>a0e3X00001ao2GhQAI</code></p>
                <p>Salesforce-Link: <br/><code><code>https://gulp-switch.lightning.force.com/lightning/r/cxsrec__cxsJob_application__c/a0e3X00001ao2GhQAI/view</code></code></p>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} md={6}>
                        <CustomInput
                            id="applicationId"
                            labelText="Ansprechpartner-ID oder Salesforce-Link"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e => setApplicationId(e.target.value),
                                value: applicationId
                            }}
                        />
                    </GridItem>
                </GridContainer>

                {validApplicationId && <Button color="primary" onClick={ () => submit() }>
                    weiter
                </Button>}
            </CardBody>
        </Card>
    )
}