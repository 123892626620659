import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../components/Microcomponents/LoadingSpinner";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import {Add, CheckBox, CheckBoxOutlineBlank, Close, Warning} from "@material-ui/icons";
import axios from "axios";
import {API_ENDPOINT, API_HEADERS} from "../../components/constants";
import CardHeader from "../../components/Card/CardHeader";
import moment from 'moment';
import 'moment/locale/de';
import _ from 'lodash';
import modalStyles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import {Dialog, DialogContent, DialogTitle, Slide} from "@material-ui/core";
import {
    cardSubtitle,
    cardLink, primaryColor
} from "assets/jss/material-dashboard-pro-react.js";
import {makeStyles} from "@material-ui/core/styles";
import Muted from "../../components/Typography/Muted";
import Badge from "../../components/Badge/Badge";
import Table from "components/Table/Table.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Fuse from "fuse.js";
import CardIcon from "../../components/Card/CardIcon";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";


const style = {
    ...extendedTablesStyle,
};


class Delphi extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showPrototypeAlert: true,
            query1: "",
            query2: "",
            query3: "",
            query4: "",
            query5: "",
            city: "München",
            isLive: false,
            excludeConsultancies: true,
            excludeEducationals: true,
            perimeter: 50,
            results: [],
            companies: [],
            selectedItems: [],
            noResults: false,
            showCompanies: false,
            showModal: false,
        }
    }
    runSearch(){
        this.setState({
            loading: true,
            error: undefined
        });
        const {
            query1,
            query2,
            query3,
            query4,
            query5,
            city,
            isLive,
            excludeConsultancies,
            excludeEducationals,
            perimeter,
        } = this.state;
        axios({
            url: `${API_ENDPOINT}/delphi/ask_delphi/`,
            method: 'POST',
            data: {
                query1,
                query2,
                query3,
                query4,
                query5,
                city,
                isLive,
                excludeConsultancies,
                excludeEducationals,
                perimeter
            },
            headers: API_HEADERS
        }).then( r => {
            this.setState({
                loading: false,
                results: r.data.results,
                companies: _.groupBy(r.data.results, el => el.company),
                selectedCompanies: Object.keys(_.groupBy(r.data.results, el => el.company)),
                noResults: r.data.results.length < 1
            });
        }).catch(err => {
            console.log(err)
            this.setState({
                loading: false,
                error: err.response.data ? err.response.data.error : undefined
            });
        })
    }
    handleSelect(key){
        let {selectedItems} = this.state;
        if (selectedItems.includes(key)){
            let keyIndex = selectedItems.findIndex( el => el === key);
            selectedItems.splice(keyIndex, 1);
        } else {
            selectedItems.push(key)
        }
        this.setState({ selectedItems });
    }
    render(){
        const {showModal, isLive, excludeConsultancies, excludeEducationals, query1, query2, query3, query4, query5, city, perimeter, loading, showPrototypeAlert, error, results, noResults, selectedItems, companies, showCompanies, selectedCompanies} = this.state;
        const invalidFilter = query1.length < 1 || city.length < 1 || perimeter < 1 ||query1.includes(" AND ") || query2.includes(" AND ") || query3.includes(" AND ") || query4.includes(" AND ") || query5.includes(" AND ") || city.length < 1
        const {classes} = this.props;
        return <div>
            {loading && <LoadingSpinner/>}
            {showPrototypeAlert && <SnackbarContent
                message={<strong>Dieses Feature befindet sich noch in der Pre-Beta Phase. Du kannst es testen, aber der Funktionsumfang ist stark eingeschränkt. Wir arbeiten aber aktiv daran.</strong>}
                close
                icon={Warning}
                onClose={ () => this.setState({ showPrototypeAlert: false })}
                color="warning"
            />}
            {error && <SnackbarContent
                message={<strong>{error}</strong>}
                close
                icon={Warning}
                onClose={ () => this.setState({ error: undefined })}
                color="danger"
            />}
            <GridContainer>
                <GridItem xs={12} md={4}>
                    <Card>
                        <CardBody>
                            <p>
                                <small>Du kannst ähnliche Begriffe mit <code>OR</code> verknüpfen.
                                <br/><code>AND</code> kannst du aber nicht verwenden - nutze hierfür einen weiteren Filter.
                                <br/>
                                <br/>
                                Du kannst zudem nur einen Ort eintragen.
                                </small>
                            </p>
                            <CustomInput
                                labelText="Filter 1"
                                id="query1"
                                error={query1.length < 1 || query1.includes(" AND ")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ query1: e.target.value }),
                                    value: query1,
                                    placeholder: "bspw. Maschinenbau* OR Konstrukt*"
                                }}
                            />

                            <CustomInput
                                labelText="Filter 2"
                                id="query2"
                                error={query2.includes(" AND ")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ query2: e.target.value }),
                                    value: query2,
                                    placeholder: "bspw. SolidWorks OR CATIA*"
                                }}
                            />
                            <CustomInput
                                labelText="Filter 3"
                                id="query3"
                                error={query3.includes(" AND ")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ query3: e.target.value }),
                                    value: query3
                                }}
                            />
                            <CustomInput
                                labelText="Filter 4"
                                id="query4"
                                error={query4.includes(" AND ")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ query4: e.target.value }),
                                    value: query4
                                }}
                            />
                            <CustomInput
                                labelText="Filter 5"
                                id="query5"
                                error={query5.includes(" AND ")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ query5: e.target.value }),
                                    value: query5
                                }}
                            />
                            <CustomInput
                                labelText="Stadt"
                                id="city"
                                error={city.length < 1 || city.includes(" OR ")}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ city: e.target.value }),
                                    value: city
                                }}
                            />
                            <CustomInput
                                labelText="Umkreis in km"
                                id="perimeter"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => {
                                        if (e.target.value.length > 0){
                                            this.setState({ perimeter: e.target.value.replace(/[^0-9]/g,"") })
                                        } else {
                                            this.setState({ perimeter: 0 })
                                        }
                                    },
                                    value: perimeter
                                }}
                            />

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isLive}
                                        onChange={ event => this.setState({ isLive: event.target.checked}) }
                                        value={isLive}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="Nur aktuelle Stellen"
                            />

                            <br/>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={excludeConsultancies}
                                        onChange={ event => this.setState({ excludeConsultancies: event.target.checked}) }
                                        value={excludeConsultancies}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="Dienstleister/Vermittler ausschließen"
                            />

                            <br/>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={excludeEducationals}
                                        onChange={ event => this.setState({ excludeEducationals: event.target.checked}) }
                                        value={excludeEducationals}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="Praktika/Azubi/Werkie ausschließen"
                            />

                            {!invalidFilter && <div>
                                <Button color="primary" onClick={ this.runSearch.bind(this) }>
                                    SUCHEN
                                </Button>
                            </div>}
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} md={8}>
                    {noResults
                        ? <SnackbarContent
                            message={<strong>Leider konnten keine Ergebnisse gefunden werden. Bitte ändere deine Suche.</strong>}
                            color="info"
                        />
                        : <div style={{ display: "flex" , justifyContent: "space-between", alignItems: "center"}}>
                            <div>
                                {showCompanies
                                    ? <Muted>{Object.keys(companies).length} Firmen</Muted>
                                    : <Muted>{results.length} Ergebnisse</Muted>
                                }
                            </div>
                            <div>
                                { showCompanies
                                    ? <Button size="sm" color="primary" onClick={() => this.setState({showCompanies: false, selectedCompanies: Object.keys(companies)})}>zur Jobansicht wechseln</Button>
                                    : <Button size="sm" color="primary" onClick={() => this.setState({showCompanies: true})}>zur Firmenliste wechseln</Button>
                                }
                            </div>
                            {!showCompanies && <div style={{display: "flex", alignItems: "center", visibility: results.length > 0 ? "unset": "hidden"}}>
                                <span style={{marginRight: 8}}>{selectedItems.length} ausgewählt</span>
                                { selectedItems.length === results.length
                                    ? <Button color="primary" size="sm" onClick={()=>this.setState({selectedItems: []})}>Auswahl aufheben</Button>
                                    : <Button color="primary" size="sm" onClick={()=>this.setState({selectedItems: results.map( el => el.id)})}>alle auswählen</Button>
                                }
                                { selectedItems.length > 0 && <Button color="success" size="sm" onClick={() => this.setState({showModal: true})}>
                                    In Liste speichern
                                </Button>}
                            </div>}
                        </div>
                    }
                    {!showCompanies
                        ? <section>
                            {_.filter(results, result => selectedCompanies.includes(result.company)).map(result => <JobItem
                                key={result.id}
                                jobTitle={result.jobTitle}
                                jobTitleCategory={result.jobTitleCategory}
                                company={result.company}
                                locationName={result.locationName}
                                distance={result.distance}
                                professionalSkills={result.professionalSkills}
                                itSkills={result.itSkills}
                                tasks={result.tasks}
                                requirements={result.requirements}
                                posterName={result.posterName}
                                posterEmail={result.posterEmail}
                                posterPhone={result.posterPhone}
                                dateAdded={result.dateAdded}
                                industry={result.industry}
                                isExpired={result.isExpired}
                                isSelected={selectedItems.includes(result.id)}
                                toggleSelect={this.handleSelect.bind(this, result.id)}
                            />)}
                        </section>
                        : <section>
                            {Object.keys(companies).sort().map((company, key) => <CompanyItem
                                key={key}
                                company={company}
                                jobs={companies[company]}
                                onSelect={() => this.setState({selectedCompanies: [company], showCompanies: false})}
                            />)}
                        </section>
                    }
                </GridItem>
            </GridContainer>
            <ActionModal
                toggle={ () => this.setState({showModal: !showModal}) }
                open={showModal}
                selectedJobs={_.filter(results, el => selectedItems.includes(el.id))}
                setLoading={loading => this.setState({ loading })}
            />
        </div>
    }
}
export default withStyles(style)(Delphi);



const cardStyles = {
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "500",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    cardSubtitle,
    cardLink,
    a: {
        color: primaryColor[0],
    }
}

const useStyles = makeStyles(cardStyles);

function JobItem ({toggleSelect, isSelected, jobTitle ,jobTitleCategory ,company ,industry ,locationName ,distance ,professionalSkills ,itSkills ,tasks ,requirements ,posterName ,posterEmail ,posterPhone ,dateAdded, isExpired}) {
    const [data, setData] = useState([])
    const [companyName, setCompanyName] = useState(company)
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();
    const searchMatches = () =>{
        axios({
            url: `${API_ENDPOINT}/delphi/ask_delphi?companyName=${companyName}`,
            headers: API_HEADERS
        }).then(r => {
            if (r.data.results.length > 0){
                setData(r.data.results)
            } else {
                setData([{id: null, name: "Keine Ergebnisse gefunden"}])
            }
        }).catch(err=>console.log(err))
    }
    return <Card>
        <CardHeader color="primary">
            <h4 className={classes.cardTitle} onClick={toggleSelect}>
                {isSelected ? <CheckBox/> : <CheckBoxOutlineBlank/>}
                {jobTitle}
            </h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{company}, {locationName} </span>
                <span>{!isExpired && <Badge color="success">live</Badge>}(<i className="fas fa-location-arrow"/> {Math.round(distance)} km)</span>
            </div>
        </CardHeader>
        <CardBody>
            <div>
                <Muted>Kategorie: {jobTitleCategory}</Muted><br/>
                <Muted>Branche: {industry}</Muted>
            </div>
            <div>
                {itSkills.map( skill => <Badge key={skill.value} color="gray">{skill.label}</Badge>)}
            </div>
            <div>
                {professionalSkills.map( skill => <Badge key={skill.value} color="gray">{skill.label}</Badge>)}
            </div>
            {expanded && <div>
                <hr/>
                <h6>Stellenbeschreibung:</h6>
                <p>{tasks}</p>
                <hr/>
                <h6>Bewerberprofil:</h6>
                <p>{requirements}</p>
                <hr/>
                <h6>Ansprechpartner:</h6>
                <p>{posterName ? posterName : <strong>Angabe zu Name des Ansprechpartners fehlt</strong>}</p>
                <p>{posterEmail ? posterEmail : <strong>Angabe zu Email des Ansprechpartners fehlt</strong>}</p>
                <p>{posterPhone ? posterPhone : <strong>Angabe zu Telefon des Ansprechpartners fehlt</strong>}</p>
                <hr/>
                <p>veröffentlich am: {moment(dateAdded).format("DD.MM.YYYY")}</p>
                <hr/>
                <p><strong>Mögliche Salesforce Einträge zu dieser Firma:</strong></p>

                <div style={{display: "flex", alignItems: "baseline"}}>
                    <CustomInput
                        labelText="Firma"
                        id="companyNameInput"
                        formControlProps={{
                            fullWidth: true,
                            style: {width: 300}
                        }}
                        inputProps={{
                            onChange: e => setCompanyName(e.target.value),
                            value: companyName
                        }}
                    />
                    <Button size="sm" color="primary" onClick={()=>searchMatches()}>
                        suchen
                    </Button>
                </div>

                <div>
                    <Table
                        striped
                        hover
                        tableData={data.map(match => [
                            match.id
                                ? <a href={`https://gulp-switch.lightning.force.com/lightning/r/Account/${match.id}/view`} target="_blank" rel="noopener noreferrer" style={{color: primaryColor[0]}}>
                                    {match.name} {match.customerReports > 0 && <i className="fas fa-circle text-success"/>}
                                </a>
                                : "Keine Ergebnisse gefunden...",
                            <span>{match.street}, {match.zipCode} {match.city}</span>
                        ])}
                        customCellClasses={[
                            classes.center,
                            classes.right,
                            classes.right
                        ]}
                        // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                        customClassesForCells={[0, 4, 5]}
                        customHeadCellClasses={[
                            classes.center,
                            classes.right,
                            classes.right
                        ]}
                        // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
                        customHeadClassesForCells={[0, 4, 5]}
                    />
                </div>

            </div>}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button color="primary" round size="sm" onClick={ () => setExpanded(!expanded)}>
                    {expanded ? "weniger..." : "mehr..."}
                </Button>
            </div>
        </CardBody>
    </Card>
}

function CompanyItem ({company, jobs, onSelect}) {
    const classes = useStyles();
    const minDistance = Math.round(_.minBy(jobs, "distance").distance);
    const maxDistance = Math.round(_.maxBy(jobs, "distance").distance);
    return <Card>
        <CardHeader color="primary">
            <h4 className={classes.cardTitle} onClick={onSelect}>
                {company}
            </h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{jobs.length} Stellenanzeigen</span>
                {minDistance === maxDistance
                    ? <span><i className="fas fa-location-arrow"/> {maxDistance} km</span>
                    : <span><i className="fas fa-location-arrow"/> zwischen {minDistance} - {maxDistance} km</span>
                }
            </div>
        </CardHeader>
        <CardBody>

        </CardBody>
    </Card>
}

const useModalStyles = makeStyles(modalStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function ActionModal({open, toggle, selectedJobs, setLoading}){
    const [selectedList, setSelectedList] = useState(undefined);
    const classes = useModalStyles();
    const updateList = (list) => {
        setLoading(true);
        axios({
            url: API_ENDPOINT + "/delphi/add_to_delphi_board/",
            method: 'post',
            headers: API_HEADERS,
            data: {
                list,
                selectedJobs
            }
        }).then(r => {
            setLoading(false);
            toggle();
        }).catch( err => console.log(err))
    }
    return <Dialog
        fullWidth={true}
        maxWidth="lg"
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        open={open}
        transition={Transition}
        keepMounted
        onClose={toggle}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
    >
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
        >
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={toggle}
            >
                <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Ergebnisse in Liste speichern</h4>
        </DialogTitle>
        <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
        >

            <GridContainer>

                <ListPicker
                    onSelect={list=>setSelectedList(list)}
                    selectedList={selectedList}
                    handleClick={ () => updateList(selectedList) }
                />

                <CreateNewList
                    onCreate={ list => updateList(list) }
                />

            </GridContainer>
        </DialogContent>
    </Dialog>
}


const useListStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    item: {
        marginBottom: "1rem",
        boxShadow: "0 0 10px 1px rgba(0,0,0,.25)",
        cursor: "pointer",
        "&:hover": {
            background: primaryColor[0],
            color: "#fff",
        }
    },
    active: {
        background: primaryColor[0],
        color: "#fff",
    }
}));

function ListPicker({selectedList, onSelect, handleClick}){
    const [lists, setLists] = useState([]);
    const [filter, setFilter] = useState("");
    const classes = useListStyles();

    useEffect(() => {
        axios({
            url: API_ENDPOINT + "/delphi/get_delphi_boards",
            headers: API_HEADERS
        }).then( r => {
            setLists(r.data);
        }).catch( err => console.log(err) )
    }, [])

    const filterLists = () => {
        let _lists = lists;
        if (filter.length > 2){
            const fuseOptions = {
                keys: ['name', 'description'],
            };
            const fuse = new Fuse(_lists, fuseOptions);
            _lists = fuse.search(filter).map( el => el.item);
        }
        return _lists
    }

    return <GridItem xs={7}>
        <section>
            <CustomInput
                labelText="Liste suchen"
                id="searchListInput"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    onChange: e => setFilter(e.target.value),
                    value: filter,
                    placeholder: "Liste suchen..."
                }}
            />
            <List className={classes.root}>
                {filterLists().map( list => <ListItem key={list.id} alignItems="flex-start" className={classes.item + " " + (selectedList && (selectedList.id === list.id) ? classes.active : "")} onClick={() => onSelect(list)}>
                    <ListItemText
                        primary={list.id + " | " + list.name}
                        secondary={list.description}
                    />
                    {selectedList && (selectedList.id === list.id) && <CheckBox/>}
                </ListItem>)}
            </List>
        </section>

        {selectedList && <Button color="primary" onClick={ handleClick }>
            zu Liste hinzufügen
        </Button>}
    </GridItem>
}

const createNewStyles = {
    ...dashboardStyle,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
const useCreateNewStyles = makeStyles(createNewStyles);

function CreateNewList({onCreate}){
    const classes = useCreateNewStyles();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    return <GridItem xs={5}>
        <Card>
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <Add />
                </CardIcon>
            </CardHeader>
            <CardBody>
                <h4 className={classes.cardTitle}>Neue Liste/Delphi-Board erstellen</h4>
                Wenn Du keine passende Liste gefunden hast, kannst Du hier eine neue erstellen.

                <CustomInput
                    labelText="Name"
                    id="newListNameInput"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: e => setName(e.target.value),
                        value: name,
                        placeholder: "Name der Liste"
                    }}
                />

                <CustomInput
                    labelText="Beschreibung"
                    id="newListDescriptionInput"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: e => setDescription(e.target.value),
                        value: description,
                        placeholder: "Beschreibung der Liste",
                        multiline: true
                    }}
                />

                {name.length > 0 && description.length > 0 && <Button color="primary" onClick={ () => onCreate({name, description}) }>
                    Liste mit Auswahl erstellen
                </Button>}
            </CardBody>
        </Card>
    </GridItem>
}