import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import 'moment/locale/de';
import {API_ENDPOINT, API_HEADERS, dateRex, TEXTKERNEL_SKILL_CATEGORIES} from "../../../../components/constants";
import AutoCompleteAsync from "../../../../components/AutoComplete/AutoCompleteAsync";
import axios from "axios";
import Badge from "../../../../components/Badge/Badge";

import AddIcon from '@material-ui/icons/Add';
import Rating from '@material-ui/lab/Rating';
import {makeStyles} from "@material-ui/core/styles";
import {Box, Chip} from "@material-ui/core";
import {primaryColor} from "../../../../assets/jss/material-dashboard-pro-react";
import Divider from "../../../../components/Divider/Divider";


const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
};


class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProfession: undefined,
            suggestedSkills: [],
            selectedSkills: [],
        };
    }

    sendState() {
        return this.state;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
    }

    isValidated(){
        return true
    }

    lookupSkills(){
        let {selectedProfession} = this.state;
        if (!selectedProfession) return;
        axios({
            url: API_ENDPOINT + "/tk/suggest_skills_for_profession/" + selectedProfession.code_id,
            headers: API_HEADERS
        }).then( r => {
            this.setState({
                suggestedSkills: r.data
            })
        }).catch( err => console.log(err) )
    }

    handleSkillClick(skill){
        let {selectedSkills} = this.state;
        let skillIndex = selectedSkills.findIndex( el => el.code_id === skill.code_id);
        if (skillIndex === -1){
            selectedSkills.push(skill)
        } else {
            selectedSkills.splice(skillIndex, 1)
        }
        this.setState({ selectedSkills })
    }

    updateSelectedSkill(skill){
        let {selectedSkills} = this.state;
        let skillIndex = selectedSkills.findIndex( el => el.code_id === skill.code_id);
        selectedSkills[skillIndex] = skill;
        this.setState({ selectedSkills });
    }

    render() {
        const {suggestedSkills, selectedProfession, selectedSkills} = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        Ihre Qualifikation
                    </h4>
                    <p className={classes.infoDescription}>
                        Helfen Sie uns dabei, Ihre Kenntnisse besser einzuordnen.
                    </p>
                </GridItem>

                {/* PROFESSION */}
                <GridItem xs={12} md={4}>
                    <AutoCompleteAsync
                      url={`${API_ENDPOINT}/tk/autocomplete_professions`}
                      handleChange={selectedProfession => this.setState({selectedProfession}, this.lookupSkills)}
                      labelKey={option => `${option.description}`}
                      name="Ich bewerbe mich als..."
                      optionRenderer={option => <div style={{width: '100%'}}>
                          <span>{option.description}</span>
                      </div>}
                    />
                    <SelectedSkills
                      selectedSkills={selectedSkills}
                      onChange={this.updateSelectedSkill.bind(this)}
                      handleOnDelete={ (skill) => this.handleSkillClick(skill) }
                    />
                </GridItem>

                {/* CONTACT INFORMATION */}
                <GridItem xs={12} md={8}>
                    {selectedProfession && <div>
                        <AutoCompleteAsync
                          url={`${API_ENDPOINT}/tk/autocomplete_skills`}
                          handleChange={selectedSkill => {
                              if (selectedSkill != null){
                                  selectedSkill.rating = 4; // adds default rating of 4
                                  selectedSkills.push(selectedSkill);
                                  this.setState({selectedSkill})
                              }
                          }}
                          filterOptions={ (options, state) => {
                              selectedSkills.map( skill => {
                                  let index = options.findIndex( el => el.code_id === skill.code_id);
                                  if (index !== -1){
                                      options.splice(index, 1)
                                  }
                              })
                              return options
                          }}
                          labelKey={option => `${option.description}`}
                          name={`Meine Top-Kenntnisse... (max. ${selectedSkills.length} von 10)`}
                          optionRenderer={option => <div style={{width: '100%'}}>
                              <span>{option.description}</span>
                              <br/>
                              <small style={{opacity: .5}}>{TEXTKERNEL_SKILL_CATEGORIES[option.category]}</small>
                          </div>}
                        />
                        <div className="progress-bar progress-bar-success"></div>
                        <Divider label={<p style={{margin: "1rem", opacity: .5 }}>
                            ...oder Vorschläge auswählen <i className="fas fa-caret-down"/>
                        </p>}/>
                        {suggestedSkills.map( skill => {
                            if (selectedSkills.findIndex( el => el.code_id === skill.code_id) === -1) {
                                return <Badge key={skill.code_id} color="gray" className="as-link m-1" onClick={this.handleSkillClick.bind(this, skill)}>
                                    {skill.description.description ? skill.description.description : skill.description} <i className="fas fa-plus" />
                                </Badge>
                            } else {
                                return true
                            }
                        })}
                    </div>}
                </GridItem>
            </GridContainer>
        );
    }
}

Step2.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step2);


function SelectedSkills ({selectedSkills, onChange, handleOnDelete}) {
    return <div>
        {selectedSkills.length > 0 && <p
          style={{color: primaryColor[0], textTransform: 'uppercase', marginTop: "2rem"}}
          className="animate__animated animate__backInRight">
            Ihre Kenntnisse bewerten
        </p>}
        {selectedSkills.map( skill => <HotSkill
          key={skill.code_id}
          data={skill}
          onChange={onChange}
          onDelete={() => handleOnDelete(skill)}
        />)}
    </div>
}

const RatingLabels = {
    1: 'Anfänger',
    2: 'Fortgeschritten',
    3: 'Gut',
    4: 'Sehr gut',
    5: 'Experte'
};

const useRatingStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,.1)",
        fontSize: "1rem",
        marginBottom: ".5rem",
    },
    deleteIcon: {
        visibility: "hidden",
        marginLeft: ".5rem",
        fontSize: ".75rem",
        opacity: .5,
    },
    label: {
        cursor: "pointer",
        "&:hover > .fa-times": {
            opacity: 1,
            visibility: "visible",
        }
    },
    rating: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
    },
});

const StyledRating = withStyles({
    iconFilled: {
        color: primaryColor[0],
    },
    iconHover: {
        color: primaryColor[1],
    },
})(Rating);

function HotSkill ({data, onChange, onDelete}){
    const [hover, setHover] = React.useState(-1);
    const classes = useRatingStyles();
    return <div className={ classes.root}>
        <strong className={classes.label} onClick={onDelete}>
            {data.description.description ? data.description.description : data.description}
            <i className={classes.deleteIcon + " fas fa-times"}/>
        </strong>
        <div className={classes.rating}>
            <StyledRating
              size="large"
              name={`hotskillRating-${data.code_id}`}
              value={data.value}
              precision={1}
              onChange={(event, newValue) => {
                  data.rating = newValue
                  onChange(data);
              }}
              onChangeActive={(event, newHover) => {
                  setHover(newHover);
              }}
            />
            {data.rating !== null && <Box ml={2}>{RatingLabels[hover !== -1 ? hover : data.rating]}</Box>}
        </div>
    </div>
}
