import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {InputLabel} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import {
    tooltip
} from "assets/jss/material-dashboard-pro-react.js";
import {currencyFormatter, validateFee} from "../../../../components/commonFunctions";


const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
    ...tooltip,
};


const DOUBLE_SPLIT_FEE_DUE_DATE_OPTIONS = {
    1: [
        {value: "on_search_begin", label: "nach Auftragserteilung"},
        {value: "on_signed_employment_contract", label: "zum Zeitpunkt der Vertragsunterschrift des vermittelten Bewerbers"},
        {value: "on_employee_start_date", label: "nach Aufnahme der Beschäftigung des vermittelten Bewerbers"},
    ],
    2: [
        {value: "on_signed_employment_contract", label: "zum Zeitpunkt der Vertragsunterschrift des vermittelten Bewerbers"},
        {value: "on_employee_start_date", label: "nach Aufnahme der Beschäftigung des vermittelten Bewerbers"},
        {value: "3_months_after_employee_start_date", label: "drei Monate nach Aufnahme der Beschäftigung des vermittelten Bewerbers"},
        {value: "after_employee_probation_period", label: "zum Ende der Probezeit des vermittelten Bewerbers"},
    ]
}

const TRIPLE_SPLIT_FEE_DUE_DATE_OPTIONS = {
    1: [
        {value: "on_search_begin", label: "nach Auftragserteilung sofort"},
        {value: "on_signed_employment_contract", label: "zum Zeitpunkt der Vertragsunterschrift des vermittelten Bewerbers"}
    ],
    2: [
        {value: "on_final_interview", label: "nach Einladung eines durch Randstad vorgestellten Bewerbers zu einem finalen Vorstellungsgesprächs"},
        {value: "on_signed_employment_contract", label: "zum Zeitpunkt der Vertragsunterschrift des vermittelten Bewerbers"},
        {value: "on_employee_start_date", label: "nach Aufnahme der Beschäftigung des vermittelten Bewerbers"},
    ],
    3: [
        {value: "on_signed_employment_contract", label: "zum Zeitpunkt der Vertragsunterschrift des vermittelten Bewerbers"},
        {value: "on_employee_start_date", label: "nach Aufnahme der Beschäftigung des vermittelten Bewerbers"},
        {value: "3_months_after_employee_start_date", label: "drei Monate nach Aufnahme der Beschäftigung des vermittelten Bewerbers"},
        {value: "after_employee_probation_period", label: "zum Ende der Probezeit des vermittelten Bewerbers"},
    ]
}


class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fee: "35 Prozent",
            feeMinimum: "15.000,00 EUR",
            feeType: "contingency",
            inv1Amount: "5.000,00 EUR",
            inv1DueDate: "on_search_begin",
            inv2Amount: "5.000,00 EUR",
            inv2DueDate: "on_signed_employment_contract",
            inv3Amount: "",
            inv3DueDate: "on_signed_employment_contract",
        };
    }

    sendState() {
        return this.state;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
    }

    isValidated(){
        const {
            fee,
            feeType,
            inv1Amount,
            inv2Amount,
            inv3Amount,
        } = this.state;
        if (feeType === "contingency"){
            return validateFee(fee, 5000, 18, 50)
        }
        if (feeType === "double-split"){
            return validateFee(fee, 2000, 18, 80) && validateFee(inv1Amount, 2000, 18, 80) && (validateFee(inv2Amount, 1000, 18, 80) || inv2Amount === "")
        }
        if (feeType === "triple-split"){
            return validateFee(fee, 2000, 18, 80) && validateFee(inv1Amount, 2000, 18, 80) && validateFee(inv2Amount, 5000, 18, 80) && (validateFee(inv3Amount, 1000, 18, 80) || inv3Amount === "")
        }
    }

    getDueDateOptions(step){
        let {feeType} = this.state;
        if (feeType === "double-split"){
            return DOUBLE_SPLIT_FEE_DUE_DATE_OPTIONS[step]
        }
        if (feeType === "triple-split"){
            return TRIPLE_SPLIT_FEE_DUE_DATE_OPTIONS[step]
        }
    }

    render() {
        const {
            fee,
            feeMinimum,
            feeType,
            inv1Amount,
            inv1DueDate,
            inv2Amount,
            inv2DueDate,
            inv3Amount,
            inv3DueDate,
        } = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        Konditionen (allgemein)
                    </h4>
                    <p className={classes.infoDescription}>
                        Allgemeine Vermittlungskonditionen bzw. Honorarordnung
                    </p>
                </GridItem>

                {/* FEE TERMS */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* FEE RATE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<Tooltip
                                    id="fee-tooltip"
                                    title="Du kannst mit Prozent bzw. % einen Provisionssatz wählen (min. 18%, max. 50%) oder mit EURO bzw. € eine Vermittlungspauschale (min. 5.000,00 EUR)."
                                    placement="top-start"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <span>
                                        Vermittlungsprovision / -Pauschale <i className="fas fa-question-circle text-muted"/>
                                    </span>
                                </Tooltip>}
                                success={validateFee(fee, 5000, 18, 50)}
                                error={!validateFee(fee, 5000, 18, 50)}
                                id="terms-fee"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ fee: e.target.value }),
                                    onBlur: e => {
                                        let formattedFee = fee.toUpperCase().replace("€", " EUR").replace("EURO", " EUR");
                                        formattedFee = formattedFee.toUpperCase().replace("%", " Prozent").replace("PROZENT", " Prozent");
                                        formattedFee = formattedFee.replace(/\s\s/g, " ");
                                        // reformat currency
                                        if (formattedFee.includes("EUR")){
                                            let amount = formattedFee.replace("EUR", "")
                                            amount = parseFloat(amount);
                                            amount = currencyFormatter.format(amount);
                                            formattedFee = amount.replace("€", "EUR");
                                        }
                                        this.setState({fee: formattedFee});
                                    },
                                    value: fee
                                }}
                            />
                        </GridItem>

                        {/* FEE MINIMUM */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<Tooltip
                                    id="feeMinimum-tooltip"
                                    title="Das Mindesthonorar wird erfahrungsgemäß sehr selten in Frage gestellt. Versuche es daher mit dem Argument der internen Kostendeckung zu begründen."
                                    placement="top-start"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <span>
                                        Untergrenze Vermittlungshonorar <i className="fas fa-question-circle text-muted"/>
                                    </span>
                                </Tooltip>}
                                id="terms-feeMinimum"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ feeMinimum: e.target.value.length > 0 ? parseFloat(e.target.value.replace(/[^0-9,]/g, '')) : "" }),
                                    onBlur: e => {
                                        if (feeMinimum > 0 && !isNaN(feeMinimum)){
                                            this.setState({ feeMinimum: currencyFormatter.format(feeMinimum).replace("€", "EUR") });
                                        }
                                    },
                                    value: feeMinimum
                                }}
                            />
                        </GridItem>

                    </GridContainer>
                </GridItem>

                {/* FEE TYPE */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* FEE TYPE */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="feeTypeSelect"
                                    className={classes.selectLabel}
                                >
                                    Fälligkeit des Honorars
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={feeType}
                                    onChange={ e => {
                                        if (e){
                                            let _inv2DueDate = inv2DueDate;
                                            let _inv2Amount = inv2Amount;
                                            if (e.target.value === "double-split"){
                                                _inv2DueDate = "on_signed_employment_contract"
                                                _inv2Amount = ""
                                            }
                                            if (e.target.value === "triple-split"){
                                                _inv2DueDate = "on_final_interview"
                                                _inv2Amount = "5.000,00 EUR"
                                            }
                                            this.setState({
                                                feeType: e.target.value,
                                                inv2DueDate: _inv2DueDate,
                                                inv2Amount: _inv2Amount
                                            })
                                        }
                                    } }
                                    inputProps={{
                                        name: "feeTypeSelect",
                                        id: "feeTypeSelect"
                                    }}
                                >
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="contingency"
                                    >
                                        In einer Rate
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="double-split"
                                    >
                                        In zwei Raten
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="triple-split"
                                    >
                                        In drei Raten
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* FIRST FEE */}
                        <GridItem xs={12}>
                            {feeType !== "contingency" && <FeeStep
                                classes={classes}
                                amount={inv1Amount}
                                dueDate={inv1DueDate}
                                feeNumber={1}
                                feeType={feeType}
                                onChangeAmount={ inv1Amount => this.setState({ inv1Amount })}
                                onChangeDueDate={ inv1DueDate => this.setState({ inv1DueDate })}
                                dueDateOptions={feeType !== "contingency" ? this.getDueDateOptions(1) : []}
                            />}
                        </GridItem>

                        {/* SECOND/LAST FEE */}
                        <GridItem xs={12}>
                            {feeType !== "contingency" && <FeeStep
                                classes={classes}
                                amount={inv2Amount}
                                dueDate={inv2DueDate}
                                feeNumber={2}
                                feeType={feeType}
                                lastFee={feeType === "double-split"}
                                onChangeAmount={ inv2Amount => this.setState({ inv2Amount })}
                                onChangeDueDate={ inv2DueDate => this.setState({ inv2DueDate })}
                                dueDateOptions={feeType !== "contingency" ? this.getDueDateOptions(2) : []}
                            />}
                        </GridItem>

                        {/* THIRD/LAST FEE */}
                        <GridItem xs={12}>
                            {feeType === "triple-split" && <FeeStep
                                classes={classes}
                                amount={inv3Amount}
                                dueDate={inv3DueDate}
                                feeNumber={3}
                                feeType={feeType}
                                lastFee={feeType === "triple-split"}
                                onChangeAmount={ inv3Amount => this.setState({ inv3Amount })}
                                onChangeDueDate={ inv3DueDate => this.setState({ inv3DueDate })}
                                dueDateOptions={feeType !== "contingency" ? this.getDueDateOptions(3) : []}
                            />}
                        </GridItem>

                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

Step3.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step3);



const FeeStep = ({feeNumber, feeType, lastFee, classes, amount, dueDate, onChangeAmount, onChangeDueDate, dueDateOptions}) => {
    return <GridContainer>
        <GridItem xs={12}>
            {lastFee && <small>Die Höhe der letzten Rate kannst Du optional auch leer lassen. In diesem Fall wird diese dynamisch berechnet - entweder anhand des Provisionssatzes oder der Vermittlungspauschale).</small>}
            <CustomInput
                labelText={<Tooltip
                    id={`${feeType}_inv${feeNumber}Amount-tooltip`}
                    title="Du kannst mit Prozent bzw. % einen Provisionssatz wählen oder mit EURO bzw. € eine Vermittlungspauschale."
                    placement="top-start"
                    classes={{ tooltip: classes.tooltip }}
                >
                    <span>
                        Höhe der {feeNumber}. Rate <i className="fas fa-question-circle text-muted"/>
                    </span>
                </Tooltip>}
                success={lastFee
                    ? (validateFee(amount, 1000, 5, 95) || amount === "")
                    : validateFee(amount, 2500, 5, 95)
                }
                error={lastFee
                    ? (!validateFee(amount, 1000, 5, 95) && amount !== "")
                    : !validateFee(amount, 2500, 5, 95)
                }
                id={`terms-${feeType}_inv${feeNumber}Amount`}
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    onChange: e => onChangeAmount(e.target.value),
                    onBlur: e => {
                        let formattedFee = amount.toUpperCase().replace("€", " EUR").replace("EURO", " EUR");
                        formattedFee = formattedFee.toUpperCase().replace("%", " Prozent").replace("PROZENT", " Prozent");
                        formattedFee = formattedFee.replace(/\s\s/g, " ");
                        // reformat currency
                        if (formattedFee.includes("EUR")){
                            let amount = formattedFee.replace("EUR", "")
                            amount = parseFloat(amount);
                            amount = currencyFormatter.format(amount);
                            formattedFee = amount.replace("€", "EUR");
                        }
                        onChangeAmount(formattedFee)
                    },
                    value: amount
                }}
            />
        </GridItem>

        <GridItem xs={12}>
            <FormControl
                fullWidth
                className={classes.selectFormControl}
            >
                <InputLabel
                    htmlFor={`terms-${feeType}_inv${feeNumber}DueDateSelect`}
                    className={classes.selectLabel}
                >
                    Fälligkeit der {feeNumber}. Rate
                </InputLabel>
                <Select
                    MenuProps={{
                        className: classes.selectMenu
                    }}
                    classes={{
                        select: classes.select
                    }}
                    value={dueDate}
                    onChange={ e => e && onChangeDueDate(e.target.value) }
                    inputProps={{
                        name: `terms-${feeType}_inv${feeNumber}DueDateSelect`,
                        id: `terms-${feeType}_inv${feeNumber}DueDateSelect`
                    }}
                >
                    {dueDateOptions.map( (option, key) =>
                        <MenuItem
                            key={key}
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                            }}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </GridItem>
    </GridContainer>
}
