import React, {useEffect, useState} from "react";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import GridContainer from "../../components/Grid/GridContainer";
import CardBody from "../../components/Card/CardBody";
import {makeStyles} from "@material-ui/core/styles";
import {cardTitle, successColor} from "../../assets/jss/material-dashboard-pro-react";
import {Tab} from "@material-ui/icons";
import axios from "axios";
import {API_ENDPOINT, API_HEADERS} from "../../components/constants";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import ReactTable from "../../components/ReactTable/ReactTable";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import _ from "lodash";
import LoadingSpinner from "../../components/Microcomponents/LoadingSpinner";
import Button from "../../components/CustomButtons/Button";

const styles = {
    ...customSelectStyle,
    customCardContentClass: {
        paddingLeft: "0",
        paddingRight: "0"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    columnPicker: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    }
};

const useStyles = makeStyles(styles);

const TableCells = [
    {value: 'order_number', label: "Bestell-Nr", width: 100, filterable: true},
    {value: 'description', label: "Beschreibung", width: 300, filterable: true},
    {value: 'account_balance_open', label: "Konti offen", width: 300, filterable: false},
    {value: 'account_balance_total', label: "Konti gesamt", width: 300, filterable: false},
    {value: 'account_balance_unit', label: "Konti Einheit", width: 300, filterable: false},
    {value: 'purchasing_price', label: "EK", width: 300, filterable: false},
    {value: 'parent_order', label: "Hauptartikel?", width: 300, filterable: true},
    {value: 'selling_price', label: "VK", width: 300, filterable: false},
    {value: 'commision_rate', label: "Provisionssatz", width: 300, filterable: false},
    {value: 'status_mds_supplier', label: "Status Lieferanten-Stammdaten", width: 300, filterable: true},
    {value: 'status_client_order', label: "Status Kundenbestellung", width: 300, filterable: true},
    {value: 'status_project_contract', label: "Status Projektvertrag", width: 300, filterable: true},
    {value: 'supplier_discount', label: "Lieferanten-Skonto", width: 300, filterable: false},
    {value: 'invoice_payable', label: "Zahlungsziel (ZZ)", width: 300, filterable: false},
    {value: 'special_invoice_payable', label: "Sonder ZZ", width: 300, filterable: false},
    {value: 'project_start', label: "Projektstart", width: 300, filterable: false},
    {value: 'project_end', label: "Projektende", width: 300, filterable: false},
    {value: 'internal_project_end', label: "Internes Projektende", width: 300, filterable: false},
    {value: 'project_contract_nr', label: "PV-Nr", width: 300, filterable: true},
    {value: 'date_added', label: "Angelegt am", width: 300, filterable: false},
    {value: 'consultant', label: "VB", width: 300, filterable: true},
    {value: 'bum', label: "BUM", width: 300, filterable: true},
    {value: 'sd', label: "SD", width: 300, filterable: true},
    {value: 'supplier', label: "Lieferant", width: 300, filterable: true},
    {value: 'supplier_contact', label: "Lieferant AP", width: 300, filterable: true},
    {value: 'client', label: "Kunde", width: 300, filterable: true},
    {value: 'job_location', label: "Einsatzort", width: 300, filterable: true},
    {value: 'sf_placement_ref', label: "SF Placement", width: 300, filterable: true},
    {value: 'active', label: "Aktiv?", width: 300, filterable: true},
    {value: 'business_entity', label: "Geschäftsbereich", width: 300, filterable: true},
]

const ColumnPicker = ({values, onChange, classes}) => <FormControl fullWidth className={classes.selectFormControl}>
    <InputLabel
        htmlFor="column-picker"
        className={classes.selectLabel}
    >
        Spalten wählen
    </InputLabel>
    <Select
        multiple
        value={values}
        onChange={e => onChange(e.target.value)}
        MenuProps={{
            className: classes.selectMenu,
            classes: { paper: classes.selectPaper }
        }}
        classes={{ select: classes.select }}
        inputProps={{
            name: "columnPicker",
            id: "column-picker"
        }}
    >
        <MenuItem
            disabled
            classes={{
                root: classes.selectMenuItem
            }}
        >
            Spalten wählen...
        </MenuItem>
        {_.sortBy(TableCells, ['label']).map( cell => <MenuItem
            key={cell.value}
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={cell.value}
        >
            {cell.label}
        </MenuItem>)}
    </Select>
</FormControl>


const getDefaultColumns = () => {
    const defaults = localStorage.getItem("organiceArtikelDefaultColumns");
    if (defaults){
        return defaults.split(",")
    } else {
        return TableCells.map( cell => cell.value )
    }
}


export default function Organice(){
    const [columns, setColumns] = useState(getDefaultColumns())
    const [dataRows, setDataRows] = useState([]);
    const [loading, setLoading] = useState([true]);
    const [sortBy, setSortBy] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [totalCount, setTotalCount] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        axios({
            url: API_ENDPOINT + `/vms/get_organice_articles?page_size=${pageSize}&current_page=${currentPage}`,
            headers: API_HEADERS
        }).then( r => {
            setDataRows(r.data.results);
            setTotalCount(r.data.total_count)
            setLoading(false)
        }).catch(
            console.log(err => console.log(err))
        )
    },[])
    const build_filters = (filters) => {
        let filter_string = "";
        filters.map( _filter => {
            filter_string = `${filter_string}&${_filter.id}=${_filter.value}`
        })
        return filter_string
    }
    const build_sorting = (sortBy) => {
        if (sortBy.length > 0){
            sortBy = sortBy[0];
            return `&sort_by=${sortBy.id}__${sortBy.desc ? "desc" : "asc"}`;
        }
        return ""
    }
    const updateData = ({pageIndex, pageSize, sortBy, filters}) => {
        setCurrentPage(pageIndex);
        setPageSize(pageSize);
        setSortBy(sortBy);
        setFilterBy(filters);
    }
    const submit = () => {
        setLoading(true)
        let url = API_ENDPOINT + `/vms/get_organice_articles?page_size=${pageSize}&current_page=${currentPage}${build_filters(filterBy)}${build_sorting(sortBy)}`;
        axios({
            url,
            headers: API_HEADERS
        }).then( r => {
            setDataRows(r.data.results);
            setTotalCount(r.data.total_count)
            setLoading(false)
        }).catch(
            console.log(err => console.log(err))
        )
    }
    const classes = useStyles()
    return <div>
        <GridContainer>
            {loading && <LoadingSpinner/>}
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon className="flex justify-content-center">
                        <GridContainer>
                            <GridItem md={8}>
                                <CardIcon color="primary">
                                    <Tab />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Organice Artikel</h4>
                            </GridItem>
                            <GridItem md={4} className={classes.columnPicker}>
                                <ColumnPicker
                                    values={columns}
                                    onChange={columns => {
                                        setColumns(columns);
                                        localStorage.setItem("organiceArtikelDefaultColumns", columns)
                                    }}
                                    classes={classes}
                                />
                                <Button color="primary" size="sm" onClick={()=>submit()}>
                                    aktualisieren
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <ReactTable
                            columns={columns.map( cell => {
                                let index = TableCells.findIndex( el => el.value === cell )
                                cell = TableCells[index]
                                return {
                                    Header: cell.label,
                                    accessor: cell.value,
                                    width: cell.width,
                                    disableFilters: !cell.filterable
                                }
                            })}
                            data={dataRows}
                            onFetchData={ updateData }
                            pageCount={Math.ceil(totalCount / pageSize)}
                            getRowProps={row => ({
                                style: {
                                    background: row.original.parent_order === "Ja" ? successColor[5] : "",
                                },
                            })}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    </div>
}