import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {InputLabel} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import {
    tooltip
} from "assets/jss/material-dashboard-pro-react.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
    ...tooltip,
};


const INVOICE_PAYABLE_OPTIONS = [5,8,10,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]
const WARRANTY_OPTIONS = [0,2,3,4,5,6,7,8,9,10,11,12]


class Step4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isExclusive: false,
            invoicePayable: 8,
            warranty: 0,
            informationPeriod: 5,
            specifiedPositions: "",
            isEnglish: false,
        };
    }

    sendState() {
        return this.state;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
    }

    isValidated(){
        return true
    }

    render() {
        const {
            isExclusive,
            invoicePayable,
            warranty,
            informationPeriod,
            specifiedPositions,
            isEnglish
        } = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        Konditionen (sonstige)
                    </h4>
                    <p className={classes.infoDescription}>
                        Sonstige Vertragsbestandteile
                    </p>
                </GridItem>

                {/* 1st COLUMN */}
                <GridItem xs={12} md={5}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<Tooltip
                                    id="specifiedPositions-tooltip"
                                    title={<div>
                                        <p>Manchmal soll ein Vertrag nicht auf unbestimmte Dauer und Umfang gelten. In diesem Fall, kannst Du hier durch ein Semikolon [;] getrennt unterschiedliche Positionen nennen. Bspw.:</p>
                                        <code>2x Java Entwickler</code>
                                        <br/>oder<br/>
                                        <code>Java Entwickler; SAP Entwickler</code>
                                    </div>}
                                    placement="top-start"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <span>
                                        Spezifische Positionen <i className="fas fa-question-circle text-muted"/>
                                    </span>
                                </Tooltip>}
                                id="others-specifiedPositions"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ specifiedPositions: e.target.value }),
                                    value: specifiedPositions
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>

                {/* 2nc COLUMN */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* EXCLUSIVITY */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="isExclusiveSelect"
                                    className={classes.selectLabel}
                                >
                                    <Tooltip
                                        id="isExclusive-tooltip"
                                        title="Exklusivität bedeutet, dass der Kunde für die Laufzeit des Vertrags mit keinen anderen Personalvermittlern zusammenarbeitet."
                                        placement="top-start"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                    <span>
                                        Exklusivität <i className="fas fa-question-circle text-muted"/>
                                    </span>
                                    </Tooltip>
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={isExclusive}
                                    onChange={ e => this.setState({ isExclusive: e.target.value }) }
                                    inputProps={{
                                        name: "isExclusiveSelect",
                                        id: "isExclusiveSelect"
                                    }}
                                >
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={true}
                                    >
                                        Ja, Exklusivität vereinbart
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={false}
                                    >
                                        Nein, keine Exklusivität vereinbart
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* INVOICE PAYABLE */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="invoicePayableSelect"
                                    className={classes.selectLabel}
                                >
                                    <Tooltip
                                        id="invoicePayable-tooltip"
                                        title="Du kannst diesen Wert flexibel bis 30 Tage wählen. Alles darüber muss von der Geschäftsführung genehmigt werden."
                                        placement="top-start"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <span>
                                            Zahlungsziel <i className="fas fa-question-circle text-muted"/>
                                        </span>
                                    </Tooltip>
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={invoicePayable}
                                    onChange={ e => this.setState({ invoicePayable: e.target.value }) }
                                    inputProps={{
                                        name: "invoicePayableSelect",
                                        id: "invoicePayableSelect"
                                    }}
                                >
                                    {INVOICE_PAYABLE_OPTIONS.map( days => <MenuItem
                                        key={days}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={days}
                                    >
                                        {days} Tage
                                    </MenuItem>)}

                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* WARRANTY */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="warrantySelect"
                                    className={classes.selectLabel}
                                >
                                    <Tooltip
                                        id="warranty-tooltip"
                                        title="Wir können dem Kunden innerhalb der ersten drei Monate eine kostenlose Nachbesetzung ohne Erfolgsgarantie bieten. Dies sollte vermieden werden, sofern möglich. Du kannst bis zu 12 Wochen nach eigenem Ermessen gewähren. Alles darüber bedarf der Zustimmung durch die Geschäftsführung."
                                        placement="top-start"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <span>
                                            Kulanzregelung bei Kündigung <i className="fas fa-question-circle text-muted"/>
                                        </span>
                                    </Tooltip>
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={warranty}
                                    onChange={ e => this.setState({ warranty: e.target.value }) }
                                    inputProps={{
                                        name: "warrantySelect",
                                        id: "warrantySelect"
                                    }}
                                >
                                    {WARRANTY_OPTIONS.map( weeks => <MenuItem
                                        key={weeks}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={weeks}
                                    >
                                        {weeks !== 0
                                            ? weeks +  " Wochen"
                                            : "Keine Kulanzregelung"
                                        }
                                    </MenuItem>)}

                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* INFORMATION PERIOD */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <InputLabel
                                    htmlFor="informationPeriodSelect"
                                    className={classes.selectLabel}
                                >
                                    <Tooltip
                                        id="informationPeriod-tooltip"
                                        title="Sofern wir dem Kunden einen Bewerber vorstellen, den er bereits kennt, muss er uns darüber informieren. Wie bald diese Information erfolgt, kannst Du hier angeben."
                                        placement="top-start"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <span>
                                            Informationspflicht <i className="fas fa-question-circle text-muted"/>
                                        </span>
                                    </Tooltip>
                                </InputLabel>
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={informationPeriod}
                                    onChange={ e => this.setState({ informationPeriod: e.target.value }) }
                                    inputProps={{
                                        name: "informationPeriodSelect",
                                        id: "informationPeriodSelect"
                                    }}
                                >
                                    {[5,10].map( days => <MenuItem
                                        key={days}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={days}
                                    >
                                        {days} Tage
                                    </MenuItem>)}

                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* IS ENGLISH */}
                        <GridItem xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isEnglish}
                                        onChange={ event => this.setState({ isEnglish: event.target.checked}) }
                                        value={isEnglish}
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.switchChecked,
                                            thumb: classes.switchIcon,
                                            track: classes.switchBar
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label
                                }}
                                label="Vertrag ist auf Englisch"
                            />
                        </GridItem>

                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

Step4.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step4);