import React from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Board, {createTranslate} from 'react-trello';
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {API_ENDPOINT, API_HEADERS, ReactTrello_i18n_de} from "../../components/constants";
import LoadingSpinner from "../../components/Microcomponents/LoadingSpinner";
import axios from "axios";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {
    MovableCardWrapper,
    CardHeader as RTCardHeader,
    CardRightContent,
    CardTitle,
    Detail
} from "react-trello/dist/styles/Base";

import DeleteButton from 'react-trello/dist/widgets/DeleteButton'
import {Dialog, DialogContent, DialogTitle, Slide} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import modalStyles from "../../assets/jss/material-dashboard-pro-react/modalStyle";
import Badge from "../../components/Badge/Badge";
import Muted from "../../components/Typography/Muted";
import moment from "moment";
import UserSelect from "../../components/Microcomponents/UserSelect";
import AutoCompleteAsync from "../../components/AutoComplete/AutoCompleteAsync";


const germanTranslation = createTranslate(ReactTrello_i18n_de);

const AddCardLink = () => <span/>

const CustomCard = ({onDelete, style, tagStyle, onClick, className, id, title, label, description, tags, t}) => <div>
    <MovableCardWrapper
        data-id={id}
        style={style}
        className={className}
    >
        <RTCardHeader>
            <CardTitle draggable onClick={onClick}>
                {title}
            </CardTitle>
            <CardRightContent>
                {label}
            </CardRightContent>
            <DeleteButton onClick={onDelete} />
        </RTCardHeader>
        <Detail>
            {description}
        </Detail>
    </MovableCardWrapper>
</div>

export default class DelphiBoard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            board: undefined,
            loading: true,
            unsavedChanges: false,
            openCard: undefined
        }
    }
    componentDidMount() {
        axios({
            url: API_ENDPOINT + "/delphi/get_delphi_board/" +  this.props.match.params.id,
            headers: API_HEADERS
        }).then( r => {
            this.setState({
                owner: r.data.owner,
                name: r.data.name,
                description: r.data.description,
                dateAdded: r.data.dateAdded,
                lastEdited: r.data.lastEdited,
                board: r.data.content,
                loading: false,
                unsavedChanges: false,
                readOnly: r.data.readOnly
            })
        }).catch( err => {
            this.setState({
                errorMessage: "Du kannst dieses Board nicht öffnen. Ggf. fehlt Dir die Berechtigung dazu."
            })
        })
    }

    updateData(){
        const {board, name, description} = this.state;
        this.setState({loading: true});
        axios({
            url: API_ENDPOINT + "/delphi/update_delphi_board/" + this.props.match.params.id + "/",
            method: 'post',
            headers: API_HEADERS,
            data: {
                name,
                description,
                board
            }
        }).then( r => {
            this.setState({
                loading: false,
                unsavedChanges: false
            })
        }).catch( err => console.log(err) )
    }

    handleCardClick(cardId, metadata, laneId){
        const {board} = this.state;
        let laneIndex = board.lanes.findIndex( el => el.id === laneId );
        let lane = board.lanes[laneIndex];
        let cardIndex = lane.cards.findIndex(el => el.id === cardId);
        let openCard = lane.cards[cardIndex];
        this.setState({ openCard })
    }

    transferBoard(){
        const {owner} = this.state;
        axios({
            url: API_ENDPOINT + "/delphi/transfer_board/",
            method: 'post',
            headers: API_HEADERS,
            data: {
                boardId: this.props.match.params.id,
                owner: owner
            }
        }).then( r => {
            window.location.reload();
        }).catch( err => console.log(err) )
    }

    render(){
        const {board, loading, errorMessage, name, description, unsavedChanges, openCard, owner, readOnly} = this.state;
        return <div>
            <Card>
                <CardHeader>
                    <GridContainer>
                        <GridItem xs={4}>
                            <small>Name der Liste</small>
                            <CustomInput
                                labelText=""
                                id="newListNameInput"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {marginBottom: 0}
                                }}
                                inputProps={{
                                    onChange: e => this.setState({name: e.target.value}),
                                    value: name,
                                    placeholder: "Name der Liste",
                                }}
                            />
                        </GridItem>
                        <GridItem xs={4}>
                            <small>Beschreibung der Liste</small>
                            <CustomInput
                                labelText=""
                                id="newListDescriptionInput"
                                formControlProps={{
                                    fullWidth: true,
                                    style: {marginBottom: 0}
                                }}
                                inputProps={{
                                    onChange: e => this.setState({description: e.target.value}),
                                    value: description,
                                    placeholder: "Beschreibung der Liste",
                                    multiline: true
                                }}
                            />
                        </GridItem>
                        <GridItem xs={4}>
                            <AutoCompleteAsync
                              url={`${API_ENDPOINT}/crm/get_all_users`}
                              handleChange={owner => owner && this.setState({owner: owner.email, promptOwnerChange: true})}
                              labelKey={option => `${option.email}`}
                              name="Liste an Kollegen übergeben"
                              optionRenderer={option => <div style={{width: '100%'}}>
                                  <span>{option.email}</span>
                              </div>}
                            />
                            {this.state.promptOwnerChange && <Button onClick={this.transferBoard.bind(this)} color="primary" size="sm">Board an {owner} übergeben</Button>}
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <CardBody>
                    {loading && <LoadingSpinner message={errorMessage}/>}
                    {!readOnly &&  <div>
                        {unsavedChanges && <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button color="success" size="sm" onClick={ this.updateData.bind(this) }>
                                Änderungen speichern
                            </Button>
                        </div>}
                    </div>}
                    {!loading && <Board
                        style={{backgroundColor: primaryColor[0]}}  // Style of BoardWrapper
                        laneStyle={{fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'}}
                        data={board}
                        onDataChange={board => this.setState({board, unsavedChanges: true})}
                        onCardDelete={(cardId, laneId) => console.log(cardId, openCard)}
                        editLaneTitle={true}
                        canAddLanes
                        laneDraggable
                        draggable
                        editable={!readOnly}
                        t={germanTranslation}
                        components={{
                            AddCardLink: AddCardLink,
                            Card: CustomCard
                        }}
                        onCardClick={this.handleCardClick.bind(this)}
                    />}
                </CardBody>
            </Card>
            <CardDetails data={openCard} toggle={()=>this.setState({openCard: undefined})} onChange={openCard => this.setState({openCard})}/>
        </div>
    }
}

const useModalStyles = makeStyles(modalStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function CardDetails({data, toggle, onChange}){
    const classes = useModalStyles();
    return <Dialog
        fullWidth={true}
        maxWidth="lg"
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        open={data !== undefined}
        transition={Transition}
        keepMounted
        onClose={toggle}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
    >
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
        >
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={toggle}
            >
                <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{data && data.title}</h4>
            <p>{data && data.description}</p>
        </DialogTitle>
        {data && <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
        >
            <GridContainer>
                <GridItem xs={7}>
                    <JobDetails data={data}/>
                </GridItem>
                <GridItem xs={5}>
                    <CustomInput
                        labelText="Notizen"
                        id="CardNotes"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: e => {
                                let _data = data;
                                _data.metadata.comment = e.target.value;
                                onChange(data)
                            },
                            value: data.metadata.comment ? data.metadata.comment : "",
                            placeholder: "Notizen",
                            multiline: true
                        }}
                    />
                </GridItem>
            </GridContainer>
        </DialogContent>}
    </Dialog>
}

const JobDetails = ({data}) => <div>
    <div>
        <Muted>Kategorie: {data.metadata.jobTitleCategory}</Muted><br/>
        <Muted>Branche: {data.metadata.industry}</Muted>
    </div>
    <div>
        {data.metadata.itSkills.map( skill => <Badge key={skill.value} color="gray">{skill.label}</Badge>)}
    </div>
    <div>
        {data.metadata.professionalSkills.map( skill => <Badge key={skill.value} color="gray">{skill.label}</Badge>)}
    </div>
    <hr/>
    <h6>Stellenbeschreibung:</h6>
    <p>{data.metadata.tasks}</p>
    <hr/>
    <h6>Bewerberprofil:</h6>
    <p>{data.metadata.requirements}</p>
    <hr/>
    <h6>Ansprechpartner:</h6>
    <p>{data.metadata.posterName ? data.metadata.posterName : <strong>Angabe zu Name des Ansprechpartners fehlt</strong>}</p>
    <p>{data.metadata.posterEmail ? data.metadata.posterEmail : <strong>Angabe zu Email des Ansprechpartners fehlt</strong>}</p>
    <p>{data.metadata.posterPhone ? data.metadata.posterPhone : <strong>Angabe zu Telefon des Ansprechpartners fehlt</strong>}</p>
    <hr/>
    <p>veröffentlich am: {moment(data.metadata.dateAdded).format("DD.MM.YYYY")}</p>
</div>
