import React, {useEffect, useState} from "react";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Step1 from "./Steps/Step1.js";
import Step2 from "./Steps/Step2.js";
import Step3 from "./Steps/Step3.js";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../../../components/CustomButtons/Button";
import {API_ENDPOINT, API_HEADERS} from "../../../components/constants";
import LoadingSpinner from "../../../components/Microcomponents/LoadingSpinner";
import ScaleLoader from "react-spinners/ScaleLoader";
import {alertOn422, redirectOn403} from "../../../components/commonFunctions";
import {CloseRounded} from "@material-ui/icons";
import {IncompleteProfileAlert} from "../../../components/helpers";
import DefaultCandidatePicker from "./ManualCandidateSelector";

export default function Wiz_CandidateQuestionnaire() {
    const [noUrlParams, setNoUrlParams] = useState(false);
    const [loadingDefaults, setLoadingDefaults] = useState(false);
    const [candidateDefaults, setCandidateDefaults] = useState(null);
    const [consultantDefaults, setConsultantDefaults] = useState(null);
    const [isCompleted, setCompleted] = useState(false);
    const [fileStatus, setFileStatus] = useState(undefined);
    const [fileLink, setFileLink] = useState(undefined);
    const [incompleteProfile, setIncompleteProfile] = useState(false);

    /*useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let candidateId = queryParams.get("candidateId");
        if (!candidateId){
            setLoadingDefaults(false);
            setNoUrlParams(true)
        }
    }, [])*/

    const submit = (allStates) => {
        if (allStates.candidate){
            setCompleted(true);
            setFileStatus("inCreation")
            let data = {
                candidate: allStates.candidate,
                consultant: allStates.consultant,
                type: "gcs_pc"
            }
            axios({
                url: API_ENDPOINT + "/gcp/create_candidate_preliminary_contract/",
                method: 'POST',
                data,
                headers: API_HEADERS
            }).then( r => {
                setFileLink(r.data.webViewLink);
                setFileStatus("ready");
            }).catch( err => {
                alertOn422(err);
                console.log(err)
            })
        }
    }

    return (
        <GridContainer justify="center">
            {noUrlParams
                ? <GridItem xs={12} sm={6}>
                    <DefaultCandidatePicker onSubmit={ () => setLoadingDefaults(true) }/>
                </GridItem>
                : <GridItem xs={12} sm={8}>
                    {!loadingDefaults
                        ? <Wizard
                            validate
                            steps={[
                                //{stepName: "Allgemeines", stepComponent: Step1, stepId: "candidate"},
                                {stepName: "Qualifikation", stepComponent: Step2, stepId: "qualification"},
                                {stepName: "GULPi", stepComponent: Step3, stepId: "consultant"},
                            ]}
                            title="Bewerberfragebogen"
                            subtitle="Mit Hilfe dieses Formulars helfen Sie uns Ihr Profil besser einzuordnen."
                            finishButtonClick={allStates => submit(allStates)}
                        />
                        : <LoadingSpinner/>
                    }
                </GridItem>
            }

            {isCompleted && <SweetAlert
                success={ fileStatus === "ready" }
                style={{ display: "block", marginTop: "-100px" }}
                title={ fileStatus === "inCreation"
                    ? <div><ScaleLoader
                        height={14}
                        width={4}
                        radius={2}
                        margin={4}
                        color="#0097a7"
                    />
                <p>Bitte warten...</p></div>
                    : "Fertig!"
                }
                showConfirm={false}
                onConfirm={ () => true } // required prop...
            >
                {fileStatus === "inCreation"
                    ? "Dein Dokument wird gerade erstellt. Bitte schließe dieses Fenster nicht."
                    : <div>
                        <CloseRounded
                            style={{position: "absolute", top: 14, right: 14, cursor: 'pointer'}}
                            onClick={()=>{
                                setCompleted(false)
                                setFileStatus(undefined)
                                setFileLink(undefined)
                            }}
                        />
                        <p>Dein Dokument stehe jetzt zum Download bereit.</p>
                        <Button color="success" onClick={ () => window.open(fileLink)}>
                            Dokument öffnen
                        </Button>
                </div>
                }
            </SweetAlert>}

            {incompleteProfile && <IncompleteProfileAlert/>}
        </GridContainer>
    );
}
