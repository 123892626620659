import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import inputStyles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle";
import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";

var defaultPickerStyles = {
    ...dashboardStyle,
    ...loginPageStyle,
    ...inputStyles,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useDefaultPickerStyles = makeStyles(defaultPickerStyles);

export default function DefaultClientPicker({onSubmit}){
    const [clientId, setClientId] = useState("");
    const classes = useDefaultPickerStyles();
    let validClientId = false;
    if (clientId.length === 18) validClientId = true;
    if (clientId.substring(0,60) === "https://gulp-switch.lightning.force.com/lightning/r/Contact/") validClientId = true;

    const submit = () => {
        let _clientId = clientId
        if (clientId.substring(0,60) === "https://gulp-switch.lightning.force.com/lightning/r/Contact/"){
            _clientId = clientId.substring(60,78)
        };
        onSubmit();
        window.open(window.location.pathname + "?clientId=" + _clientId, "_self");
    }
    return (
        <Card>
            <CardHeader>
                <h4 className={classes.cardTitle}>Ansprechpartner-ID erforderlich</h4>
                <p>Bitte gebe die Salesforce-ID Deines Ansprechpartners ein.</p>
                <p>Alternativ kannst Du auch den Link zu deinem Ansprechpartner in Salesforce eingeben.</p>
                <p><strong>Beispiele:</strong></p>
                <p>Salesforce-ID: <br/><code>0033X00003XHUMzQAP</code></p>
                <p>Salesforce-Link: <br/><code><code>https://gulp-switch.lightning.force.com/lightning/r/Contact/0033X00003XHUMzQAP/view</code></code></p>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} md={6}>
                        <CustomInput
                            id="clientId"
                            labelText="Ansprechpartner-ID oder Salesforce-Link"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e => setClientId(e.target.value),
                                value: clientId
                            }}
                        />
                    </GridItem>
                </GridContainer>

                {validClientId && <Button color="primary" onClick={ () => submit() }>
                    weiter
                </Button>}
            </CardBody>
        </Card>
    )
}