import React, {useEffect, useState} from "react";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Step1 from "./Steps/Step1.js";
import Step2 from "./Steps/Step2.js";
import Step3 from "./Steps/Step3.js";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "../../../components/CustomButtons/Button";
import {API_ENDPOINT, API_HEADERS} from "../../../components/constants";
import LoadingSpinner from "../../../components/Microcomponents/LoadingSpinner";
import ScaleLoader from "react-spinners/ScaleLoader";
import DefaultClientPicker from "../ManualClientSelector";
import {alertOn422, redirectOn403} from "../../../components/commonFunctions";
import {CloseRounded} from "@material-ui/icons";
import {IncompleteProfileAlert} from "../../../components/helpers";


export const GCS_QUOTATION_FOLDER_ID = "1l_77L1MHK2EQhyNtzttRWTsWVpFTwoFl";


export default function Wiz_GCS_Quotation() {
    const [noUrlParams, setNoUrlParams] = useState(false);
    const [loadingDefaults, setLoadingDefaults] = useState(true);
    const [clientDefaults, setClientDefaults] = useState(null);
    const [consultantDefaults, setConsultantDefaults] = useState(null);
    const [isCompleted, setCompleted] = useState(false);
    const [fileStatus, setFileStatus] = useState(undefined);
    const [fileLink, setFileLink] = useState(undefined);
    const [defaultsLoadingError, setDefaultsLoadingError] = useState(undefined);
    const [incompleteProfile, setIncompleteProfile] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let clientId = queryParams.get("clientId");
        let userEmail = queryParams.get("user");
        let url = `${API_ENDPOINT}/gcp/get_client_document_defaults?clientId=${clientId}`;
        if (userEmail){
            url = url + `&email=${userEmail}`;
        }
        if (clientId){
            axios({
                url,
                headers: API_HEADERS
            }).then( r => {
                if (!r.data.consultant.completeProfile){
                    setIncompleteProfile(true)
                }
                setClientDefaults(r.data.client);
                setConsultantDefaults(r.data.consultant);
                setLoadingDefaults(false);
                setNoUrlParams(false);
            }).catch( err => {
                if (err.response && err.response.status === 404){
                    setDefaultsLoadingError(err.response.data.error)
                }
                redirectOn403(err);
            })
        } else{
            setLoadingDefaults(false);
            setNoUrlParams(true)
        }
    }, [])

    const submit = (allStates) => {
        if (allStates.client){
            setCompleted(true);
            setFileStatus("inCreation")
            let data = {
                client: allStates.client,
                consultant: allStates.consultant,
                template: allStates.template,
                type: "gcs_quotation"
            }
            axios({
                url: API_ENDPOINT + "/gcp/create_gcs_client_quotation/",
                method: 'POST',
                data,
                headers: API_HEADERS
            }).then( r => {
                setFileLink(r.data.webViewLink);
                setFileStatus("ready");
            }).catch( err => {
                alertOn422(err);
                console.log(err)
            })
        }
    }
    return (
        <GridContainer justify="center">
            {noUrlParams
                ? <GridItem xs={12} sm={6}>
                    <DefaultClientPicker onSubmit={ () => setLoadingDefaults(true) }/>
                </GridItem>
                : <GridItem xs={12} sm={8}>
                    {!loadingDefaults
                        ? <Wizard
                            validate
                            steps={[
                                {stepName: "Kunde", stepComponent: Step1, stepId: "client", stepProps: clientDefaults},
                                {stepName: "GULPi", stepComponent: Step2, stepId: "consultant", stepProps: consultantDefaults},
                                {stepName: "Vorlage", stepComponent: Step3, stepId: "template"},
                            ]}
                            title="Neues GCS Angebot erstellen"
                            subtitle="Mit Hilfe dieses Formulars kannst du schnell und einfach konforme GCS Angebote erstellen."
                            finishButtonClick={allStates => submit(allStates)}
                        />
                        : <LoadingSpinner message={defaultsLoadingError}/>
                    }
                </GridItem>
            }

            {isCompleted && <SweetAlert
                success={ fileStatus === "ready" }
                style={{ display: "block", marginTop: "-100px" }}
                title={ fileStatus === "inCreation"
                    ? <div><ScaleLoader
                        height={14}
                        width={4}
                        radius={2}
                        margin={4}
                        color="#0097a7"
                    />
                <p>Bitte warten...</p></div>
                    : "Fertig!"
                }
                showConfirm={false}
                onConfirm={ () => true } // required prop...
            >
                {fileStatus === "inCreation"
                    ? "Dein Dokument wird gerade erstellt. Bitte schließe dieses Fenster nicht."
                    : <div>
                        <CloseRounded
                            style={{position: "absolute", top: 14, right: 14, cursor: 'pointer'}}
                            onClick={()=>{
                                setCompleted(false)
                                setFileStatus(undefined)
                                setFileLink(undefined)
                            }}
                        />
                        <p>Dein Dokument stehe jetzt zum Download bereit.</p>
                        <Button color="success" onClick={ () => window.open(fileLink)}>
                            Dokument öffnen
                        </Button>
                </div>
                }
            </SweetAlert>}

            {incompleteProfile && <IncompleteProfileAlert/>}
        </GridContainer>
    );
}