import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {emailRex} from "../../../../components/constants";


const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
};


class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gender: this.props.gender,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            position: this.props.position,
            email: this.props.email,
            phone: this.props.phone,
            mobile: this.props.mobile,
            fax: this.props.fax,
            street: this.props.street,
            zipCode: this.props.zipCode,
            city: this.props.city,
        };
    }
    sendState() {
        return this.state;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
    }

    isValidated(){
        const {
            firstName,
            lastName,
            position,
            email,
            phone,
            mobile,
            fax,
            street,
            zipCode,
            city,
        } = this.state;
        if (firstName.length > 1 && lastName.length > 1 && position.length > 3 && emailRex.test(email) && phone.length > 6 && mobile.length > 6 && fax.length > 6 && street.length > 3 && zipCode.length > 3 && city.length > 3){
            return true
        }
        return false
    }

    render() {
        const {
            gender,
            firstName,
            lastName,
            position,
            email,
            phone,
            mobile,
            fax,
            street,
            zipCode,
            city,
        } = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        GULPi
                    </h4>
                    <p className={classes.infoDescription}>
                        Bitte prüfe deine eigenen Angaben
                    </p>
                </GridItem>

                {/* CONTACT INFORMATION */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* GENDER SELECT */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={gender}
                                    onChange={ e => e && this.setState({ gender: e.target.value }) }
                                    inputProps={{
                                        name: "genderSelect",
                                        id: "genderSelect"
                                    }}
                                >
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="male"
                                    >
                                        Herr
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="female"
                                    >
                                        Frau
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* FIRST NAME */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Vorname"
                                success={firstName.length > 1}
                                error={firstName.length < 2}
                                id="consultant-firstNameInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ firstName: e.target.value }),
                                    value: firstName
                                }}
                            />
                        </GridItem>

                        {/* LAST NAME */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Nachname"
                                success={lastName.length > 1}
                                error={lastName.length < 2}
                                id="consultant-lastNameInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ lastName: e.target.value }),
                                    value: lastName
                                }}
                            />
                        </GridItem>

                        {/* POSITION */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Position"
                                success={position.length > 3}
                                error={position.length < 4}
                                id="consultant-positionInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ position: e.target.value }),
                                    value: position
                                }}
                            />
                        </GridItem>

                        {/* EMAIL */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Email"
                                success={emailRex.test(email)}
                                error={!emailRex.test(email)}
                                id="consultant-emailInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "email",
                                    onChange: e => this.setState({ email: e.target.value }),
                                    value: email
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>

                {/* ADDRESS */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* PHONE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Telefon"
                                success={phone.length > 6}
                                error={phone.length < 7}
                                id="consultant-phoneInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "phone",
                                    onChange: e => this.setState({ phone: e.target.value }),
                                    value: phone
                                }}
                            />
                        </GridItem>

                        {/* MOBILE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Mobil"
                                success={mobile.length > 6}
                                error={mobile.length < 7}
                                id="consultant-mobileInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "mobile",
                                    onChange: e => this.setState({ mobile: e.target.value }),
                                    value: mobile
                                }}
                            />
                        </GridItem>

                        {/* FAX */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Fax"
                                success={fax.length > 6}
                                error={fax.length < 7}
                                id="consultant-faxInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "fax",
                                    onChange: e => this.setState({ fax: e.target.value }),
                                    value: fax
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                    <hr/>

                    <GridContainer>
                        {/* STREET */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Straße"
                                success={street.length > 3}
                                error={street.length < 4}
                                id="consultant-streetInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ street: e.target.value }),
                                    value: street
                                }}
                            />
                        </GridItem>

                        {/* ZIP CODE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="PLZ"
                                success={zipCode.length > 3}
                                error={zipCode.length < 4}
                                id="consultant-zipCodeInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ zipCode: e.target.value }),
                                    value: zipCode
                                }}
                            />
                        </GridItem>

                        {/* ZIP CODE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Stadt"
                                success={city.length > 3}
                                error={city.length < 4}
                                id="consultant-cityInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ city: e.target.value }),
                                    value: city
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

Step2.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step2);
