import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {emailRex} from "../../../../components/constants";

const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
};


class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateId: this.props.candidateId,
            gender: this.props.gender,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            dateOfBirth: this.props.dateOfBirth,
            email: this.props.email,
            phone: this.props.phone,
            altPhone: this.props.altPhone,
            street: this.props.street,
            street2: this.props.street2,
            zipCode: this.props.zipCode,
            city: this.props.city,
            country: this.props.country,
        };
    }

    sendState() {
        return this.state;
    }

    isValidated(){
        const {
            firstName,
            lastName,
            email,
            street,
            zipCode,
            city,
            country
        } = this.state;
        return firstName.length > 1 && lastName.length > 1 && emailRex.test(email) && street.length > 4 && zipCode.length > 3 && city.length > 3 && country.length > 2

    }

    render() {
        const {
            candidateId,
            gender,
            firstName,
            lastName,
            email,
            phone,
            street,
            street2,
            zipCode,
            city,
            country
        } = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        Kandidat <code style={{marginLeft: 10}}>{candidateId}</code>
                    </h4>
                    <p className={classes.infoDescription}>
                        Bitte prüfe die Angaben zum Kandidaten
                    </p>
                </GridItem>

                {/* CONTACT INFORMATION */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* GENDER SELECT */}
                        <GridItem xs={12}>
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <Select
                                    MenuProps={{
                                        className: classes.selectMenu
                                    }}
                                    classes={{
                                        select: classes.select
                                    }}
                                    value={gender}
                                    onChange={ e => e && this.setState({ gender: e.target.value }) }
                                    inputProps={{
                                        name: "genderSelect",
                                        id: "genderSelect"
                                    }}
                                >
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="male"
                                    >
                                        Herr
                                    </MenuItem>
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value="female"
                                    >
                                        Frau
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* FIRST NAME */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Vorname"
                                success={firstName.length > 1}
                                error={firstName.length < 2}
                                id="firstNameInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ firstName: e.target.value }),
                                    value: firstName
                                }}
                            />
                        </GridItem>

                        {/* LAST NAME */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Nachname"
                                success={lastName.length > 1}
                                error={lastName.length < 2}
                                id="lastNameInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ lastName: e.target.value }),
                                    value: lastName
                                }}
                            />
                        </GridItem>

                        {/* EMAIL */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Email"
                                success={emailRex.test(email)}
                                error={!emailRex.test(email)}
                                id="consultant-emailInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    type: "email",
                                    onChange: e => this.setState({ email: e.target.value }),
                                    value: email
                                }}
                            />
                        </GridItem>

                        {/* PHONE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Telefon"
                                id="phoneInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ phone: e.target.value }),
                                    value: phone
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>

                {/* ADDRESS */}
                <GridItem xs={12} md={5}>
                    <GridContainer>
                        {/* STREET */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Straße"
                                success={street.length > 4}
                                error={street.length < 5}
                                id="streetInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ street: e.target.value }),
                                    value: street
                                }}
                            />
                        </GridItem>

                        {/* STREET 2 */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="C/O"
                                id="street2Input"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ street2: e.target.value }),
                                    value: street2
                                }}
                            />
                        </GridItem>

                        {/* ZIP CODE */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="PLZ"
                                success={zipCode.length > 3}
                                error={zipCode.length < 4}
                                id="zipCodeInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ zipCode: e.target.value }),
                                    value: zipCode
                                }}
                            />
                        </GridItem>

                        {/* CITY */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Stadt"
                                success={city.length > 3}
                                error={city.length < 4}
                                id="cityInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ city: e.target.value }),
                                    value: city
                                }}
                            />
                        </GridItem>

                        {/* COUNTRY */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Land"
                                success={country.length > 2}
                                error={country.length < 3}
                                id="countryInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ country: e.target.value }),
                                    value: country
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}

Step1.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step1);
