import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {API_HEADERS} from "../constants";
import axios from "axios";

export default function AutoCompleteAsync({url, name, labelKey, handleChange, optionRenderer, filterOptions, groupBy, renderGroup}) {
    const [query, setQuery] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(null);

    if (!filterOptions){
      filterOptions = (options, state) => options
    }

    let delayTimer;
    const handleInputChange = (query) => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            query && query.length > 0 && searchJobs(query)
        }, 1000);
    }

    const searchJobs = (query) => {
        setLoading(true);
        setOptions([]);
        axios({
            url,
            headers: API_HEADERS,
            params: {query: query}
        }).then( r => {
            setOptions(r.data);
            setLoading(false);
        }).catch( err => console.log(err) )
    }

    return (
        <Autocomplete
            id={name}
            style={{ width: "100%" }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            filterOptions={ filterOptions }
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={labelKey}
            groupBy={groupBy}
            renderGroup={renderGroup}
            onChange={(e, val) => handleChange(val)}
            options={options}
            loading={loading}
            renderOption={optionRenderer}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={name}
                    variant="outlined"
                    InputProps={{
                        value: query,
                        onChange: e => handleInputChange(e.target.value),
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}