import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    ["--text-divider-gap"]: "1rem", // set a customizable gap between label and lines
  },
  [".root::before, .root::before"]: {
    content: '',
    height: 1,
    backgroundColor: "silver",
    flexGrow: 1,
  },
  [".root::before"]: {
    marginRight: "var(--text-divider-gap)",
  },
  [".text-divider::after"]: {
    marginLeft: "var(--text-divider-gap)",
  }
});


function Divider({label}){
  const classes = useStyles();
  return <div className="text-divider">{label}</div>
}
export default Divider;