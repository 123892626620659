import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "../../assets/jss/material-dashboard-pro-react/customSelectStyle";
import {InputLabel} from "@material-ui/core";
import {Link} from "react-router-dom";
import {APPLICATION_DOCUMENT_GENERATOR_TYPES} from "../../components/constants";

var styles = {
    ...dashboardStyle,
    ...loginPageStyle,
    ...selectStyles,
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);


export default function ApplicationDocumentCreator(){
    const [docType, setDocType] = useState("");
    const classes = useStyles();
    const queryParams = new URLSearchParams(window.location.search);
    let applicationId = queryParams.get("applicationId");
    if (applicationId === "null"){
        applicationId = null;
    }
    let userEmail = queryParams.get("user");

    const getLink = () => {
        let url = "/admin/" + docType  + "?applicationId=" + applicationId;
        if (userEmail){
            url = url + "&user=" + userEmail;
        }
        return url
    }
    return ( <GridContainer justify="center">
        <GridItem xs={12} md={6}>
            {applicationId
                ? <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>Du möchtest ein Dokument generieren?</h4>
                        <p>Bitte wähle die Art des Dokuments, das Du generieren willst.</p>
                    </CardHeader>
                    <CardBody>
                        <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                        >
                            <InputLabel
                                htmlFor="documentTypeSelect"
                                className={classes.selectLabel}
                            >
                                Dokumenten-Typ wählen
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu
                                }}
                                classes={{
                                    select: classes.select
                                }}
                                value={docType}
                                onChange={e => e && setDocType(e.target.value)}
                                inputProps={{
                                    name: "documentTypeSelect",
                                    id: "documentTypeSelect"
                                }}
                            >
                                {APPLICATION_DOCUMENT_GENERATOR_TYPES.map(docType => <MenuItem
                                    key={docType.value}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    value={docType.value}
                                >
                                    {docType.label}
                                </MenuItem>)}
                            </Select>
                        </FormControl>

                        {docType && <Link to={getLink()}>
                            <Button color="primary">
                                weiter
                            </Button>
                        </Link>}
                    </CardBody>
                </Card>
                : <Card>
                    <CardHeader>
                        <h4 className={classes.cardTitle}>404 | Wie bist Du denn hier gelandet??</h4>
                    </CardHeader>
                    <CardBody>
                        <p>Diese Seite solltest Du nur aus Salesforce über den <em>Dokument generieren</em>-Button erreichen.</p>
                        <p><i className="fas fa-long-arrow-alt-left"/> Du kannst aber ach in der linken Menüleiste manuell die Dokumentenart wählen.</p>
                    </CardBody>

                </Card>
            }
        </GridItem>
    </GridContainer>)
}