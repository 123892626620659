import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import inputStyles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Tooltip from "@material-ui/core/Tooltip";
import {currencyFormatter, validateFee} from "../../../../components/commonFunctions";
import moment from 'moment';
import 'moment/locale/de';
import {dateRex} from "../../../../components/constants";


const style = {
    infoText: {
        fontWeight: "300",
        textAlign: "center"
    },
    infoDescription: {
        margin: "0 0 30px",
        textAlign: "center",
        opacity: .5
    },
    ...selectStyles,
    ...inputStyles,
    ...regularFormsStyle,
};


class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationId: this.props.applicationId,
            accountId: this.props.accountId,
            accountName: this.props.accountName,
            hourlyPayRate: this.props.hourlyPayRate,
            jobTitle: this.props.jobTitle,
            gulpProfileId: this.props.gulpProfileId,
            clientBriefing: moment().format("DD.MM.YYYY"),
            standbyDate: moment().format("DD.MM.YYYY"),
        };
    }

    sendState() {
        return this.state;
    }

    change(event, stateName, type, stateNameEqualTo) {
        this.setState({[stateName]: event.target.value});
    }

    isValidated(){
        const {
            accountName,
            hourlyPayRate,
            clientBriefing
        } = this.state;

        return accountName.length > 3 && validateFee(hourlyPayRate, 15, 18, 50, false, true) && clientBriefing.match(dateRex) !== null
    }

    render() {
        const {
            accountId,
            accountName,
            hourlyPayRate,
            clientBriefing
        } = this.state;
        const {classes} = this.props;
        return (
            <GridContainer justify="center">

                {/* HEADER */}
                <GridItem xs={12}>
                    <h4 className={classes.infoText}>
                        Bewerbung
                    </h4>
                    <p className={classes.infoDescription}>
                        Bitte prüfe die Angaben zur Bewerbung
                    </p>
                </GridItem>

                {/* CONTACT INFORMATION */}
                <GridItem xs={12} md={5}>
                    <GridContainer>

                        {/* Account ID */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Kunden-Id"
                                id="accountIdInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    value: accountId,
                                    disabled: true
                                }}
                            />
                        </GridItem>

                        {/* ACCOUNT NAME */}
                        <GridItem xs={12}>
                            <CustomInput
                                labelText="Kunde"
                                success={accountName.length > 3}
                                error={accountName.length < 4}
                                id="consultant-phoneInput"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ accountName: e.target.value }),
                                    value: accountName
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>

                {/* OTHERS */}
                {/*TODO: add this if desired*/}
                <GridItem xs={12} md={5}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={<Tooltip
                                    id="hourlyPayRate-tooltip"
                                    title="Hier gibst du den verhandelten Stundensatz in EUR/€ ein."
                                    placement="top-start"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <span>
                                        Stundensatz <i className="fas fa-question-circle text-muted"/>
                                    </span>
                                </Tooltip>}
                                success={validateFee(hourlyPayRate, 10, 18, 50, false, true)}
                                error={!validateFee(hourlyPayRate, 10, 18, 50, false, true)}
                                id="terms-fee"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ hourlyPayRate: e.target.value }),
                                    onBlur: e => {
                                        let formattedHPR = hourlyPayRate.toUpperCase().replace("€", " EUR").replace("EURO", " EUR");
                                        formattedHPR = formattedHPR.replace(/\s\s/g, " ");
                                        // reformat currency
                                        if (formattedHPR.includes("EUR")){
                                            let amount = formattedHPR.replace("EUR", "")
                                            amount = parseFloat(amount);
                                            amount = currencyFormatter.format(amount);
                                            formattedHPR = amount.replace("€", "EUR");
                                        }
                                        this.setState({hourlyPayRate: formattedHPR});
                                    },
                                    value: hourlyPayRate
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <CustomInput
                                success={clientBriefing.match(dateRex) !== null}
                                error={!clientBriefing.match(dateRex) === null}
                                labelText={<Tooltip
                                    id="clientBriefing-tooltip"
                                    title="Wann findet das Gespräch mit dem Kunden statt. Format ist strikt: TT.MM.JJJJ"
                                    placement="top-start"
                                    classes={{ tooltip: classes.tooltip }}
                                >
                            <span>
                                Kundengespräch <i className="fas fa-question-circle text-muted"/>
                            </span>
                                </Tooltip>}
                                id="terms-clientBriefing"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: e => this.setState({ clientBriefing: e.target.value.replace(/[^0-9.]/g, '') }),
                                    value: clientBriefing
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                </GridItem>
            </GridContainer>
        );
    }
}

Step2.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step2);
