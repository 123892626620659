import React, {useState} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import axios from "axios";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import DoneIcon from '@material-ui/icons/Done';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import TreeItem from '@material-ui/lab/TreeItem';
import {API_ENDPOINT, API_HEADERS} from "../../../../components/constants";
import {grayColor, infoColor, primaryColor} from "../../../../assets/jss/material-dashboard-pro-react";
import Fuse from 'fuse.js';
import ScaleLoader from "react-spinners/ScaleLoader";
import {GCS_QUOTATION_FOLDER_ID} from "../wiz_GCH_Document";

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    cardTitle: {
        marginTop: "0",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    ...extendedTablesStyle,
};

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queryText: "",
            templates: [],
            templateId: undefined,
            isTransfer: false,
            isEnglish: false,
            transferParentOrderId: "",
            loading: true
        };
    }

    componentDidMount() {
        axios({
            url: API_ENDPOINT + "/gcp/get_templates?folderId=" + GCS_QUOTATION_FOLDER_ID,
            headers: API_HEADERS
        }).then( r => {
            this.setState({
                templates: r.data,
                loading: false
            })
        }).catch( err => console.log(err) )
    }

    sendState() {
        return this.state;
    }

    isValidated(){
        const {templateId, isTransfer, transferParentOrderId} = this.state;
        if (isTransfer){
            if (transferParentOrderId !== null
                && transferParentOrderId !== undefined
                && (transferParentOrderId && transferParentOrderId.length > 0)
                && templateId !== null
                && templateId !== undefined
                && (templateId && templateId.length > 3)
            ){
                return true
            }
        } else {
            return templateId !== null
                && templateId !== undefined
                && (templateId && templateId.length > 3);
        }
    }

    render() {
        const {templates, loading, isTransfer, isEnglish, transferParentOrderId} = this.state;
        const {classes} = this.props;
        return (<div>
            <GridContainer justify="space-between">
                <GridItem xs={12} md={7}>
                    { loading
                        ? <div style={{height: 200, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            <ScaleLoader
                                height={20}
                                width={5}
                                radius={2}
                                margin={4}
                                color={infoColor[0]}
                            />
                            <p style={{color: infoColor[0], fontWeight: 700, opacity: .5}}>VORLAGEN WERDEN GELADEN...</p>
                        </div>
                        : <TemplateSelector
                            templates={templates}
                            selectTemplate={ templateId => this.setState({ templateId })}
                        />
                    }
                </GridItem>

                <GridItem xs={12} md={5}>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={isTransfer}
                                onChange={ event => this.setState({ isTransfer: event.target.checked}) }
                                value={isTransfer}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar
                                }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label="Angebot ist für eine Verlängerung"
                    />

                    {isTransfer && <CustomInput
                        labelText="Auftragsnummer des Ursprungsangebotes"
                        success={transferParentOrderId.length > 0}
                        error={transferParentOrderId.length < 1}
                        id="transferParentOrderId"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: event => this.setState({ transferParentOrderId: event.target.value }),
                            value: transferParentOrderId
                        }}
                    />}
                </GridItem>
            </GridContainer>
        </div>);
    }
}

Step3.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Step3);


const useTemplateSelectorStyles = makeStyles({
    root: {
        maxHeight: 400,
        flexGrow: 1,
        overflowY: "scroll",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        lineHeight: "1.42857",
    },
    treeLabel: {
        display: "flex",
        alignItems: "center",
        fontWeight: 300,
        padding: "4px 8px",
    },
    selectedTreeLabel: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        padding: "4px 8px",
        background: grayColor[8],
    }
});


const FolderLabel = ({name, className}) => <div className={className}>
    <FolderIcon fontSize="small" color="disabled"/>
    <span style={{marginLeft: "1rem"}}>{name}</span>
</div>


const FileLabel = ({name, selected, className}) => <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", background: selected ? grayColor[8]: "white", paddingLeft: 0}}>
    <div className={className}>
        <DescriptionIcon fontSize="small" color="disabled"/>
        <span style={{marginLeft: "1rem"}}>{name}</span>
    </div>
    {selected && <DoneIcon fontSize="small" color="disabled" style={{color: primaryColor[0]}}/>}
</div>


const TreeFileItem = ({file, handleClick, selected, className, labelClassName}) => {
    let label = file.name;
    if (file.parentFolder){
        label = file.parentFolder.toUpperCase() + " > " + file.name
    }
    return <TreeItem
        className={className}
        nodeId={file.id}
        label={<FileLabel
            className={labelClassName}
            name={label}
            selected={selected}/>
        }
        onClick={() => handleClick(file.id, file.name)}
    />
}


const CurrentTemplate = ({name}) => <div style={{display: "flex", alignItems: "center"}}>
    <span style={{opacity: .75}}>Vorlage:</span>
    <strong style={{marginLeft: ".5rem"}}>{name}</strong>
    </div>


function TemplateSelector({templates, selectTemplate}){
    const classes = useTemplateSelectorStyles();
    let [templateName, setTemplateName] = useState("");
    let [templateId, setTemplateId] = useState("");
    const [filterText, setFilterText] = useState("")
    const handleClick = (templateId, templateName) => {
        selectTemplate(templateId);
        setTemplateName(templateName);
        setTemplateId(templateId);
    }
    const filterTemplates = () => {
        if (filterText.length > 2){
            const fuseOptions = {
                keys: ['name', 'files.name'],
            };
            let flattenedTemplates = []
            templates.forEach( l1_item => {
                if (l1_item.mimeType !== "application/vnd.google-apps.folder"){
                    flattenedTemplates.push(l1_item)
                } else {
                    l1_item.files.forEach(l2_item => {
                        if (l2_item.mimeType !== "application/vnd.google-apps.folder"){
                            l2_item.parentFolder = l1_item.name;
                            flattenedTemplates.push(l2_item)
                        }
                    })
                }
            })
            const fuse = new Fuse(flattenedTemplates, fuseOptions);
            templates = fuse.search(filterText).map( el => el.item);
        }
        return templates
    }
    return <div>
        <CustomInput
            labelText={<span>
                      Vorlage suchen...
                    </span>}
            id="template"
            formControlProps={{
                fullWidth: true
            }}
            inputProps={{
                onChange: event => setFilterText(event.target.value),
                value: filterText
            }}
        />

        <SnackbarContent
            message={templateName.length > 0 ? <CurrentTemplate name={templateName}/> : "Du hast noch keine Vorlage gewählt."}
            icon={templateName.length > 0 ? DoneIcon : PriorityHigh}
            color={templateName.length > 0 ? "primary" : "info"}
        />

        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {filterTemplates().map( l1_item => {
                if (l1_item.mimeType === "application/vnd.google-apps.folder"){
                    return <TreeItem
                        key={l1_item.id}
                        className={classes.folder}
                        nodeId={l1_item.id}
                        label={<FolderLabel
                            className={classes.treeLabel}
                            name={l1_item.name}
                        />}
                    >
                        {l1_item.files.map( l2_item => {
                            if (l2_item.mimeType !== "application/vnd.google-apps.folder") {
                                return <TreeFileItem
                                    className={classes.treeItem}
                                    labelClassName={templateId === l2_item.id ? classes.selectedTreeLabel : classes.treeLabel}
                                    file={l2_item}
                                    handleClick={handleClick}
                                    key={l2_item.id}
                                    selected={templateId === l2_item.id}
                                />
                            } else {
                                return true
                            }
                        } )}
                    </TreeItem>
                } else {
                    return <TreeFileItem
                        className={classes.treeItem}
                        labelClassName={templateId === l1_item.id ? classes.selectedTreeLabel : classes.treeLabel}
                        file={l1_item}
                        handleClick={handleClick}
                        key={l1_item.id}
                        selected={templateId === l1_item.id}
                    />
                }
            })}
        </TreeView>
    </div>
}