import React, {useEffect, useState} from "react";
import Table from "components/Table/Table.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import {Link} from "react-router-dom";
import {API_ENDPOINT, API_HEADERS} from "../../components/constants";
import axios from "axios";
import LoadingSpinner from "../../components/Microcomponents/LoadingSpinner";
import moment from "moment";
import 'moment/locale/de';



const DUMMY_DATA = [
    {id: 1, name: "C++ Automotive", description: "LOREM ipsum dolor sit amet....", dateAdded: "2020-01-01 12:00:00+0100", lastEdited: "2020-01-01 12:00:00+0100", length: 4},
    {id: 2, name: "Altium", description: "LOREM ipsum dolor sit amet....", dateAdded: "2020-01-02 12:00:00+0100", lastEdited: "2020-01-02 12:00:00+0100", length: 41},
    {id: 3, name: "Embedded Software", description: "LOREM ipsum dolor sit amet....", dateAdded: "2020-01-03 14:30:00+0100", lastEdited: "2020-01-03 14:30:00+0100", length: 24},
    {id: 4, name: "Radar FPGA", description: "LOREM ipsum dolor sit amet....", dateAdded: "2020-01-06 12:00:00+0100", lastEdited: "2020-01-06 12:00:00+0100", length: 14},
]

export default function MyLists(){
    const [data, setData] = useState(DUMMY_DATA);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios({
            url: API_ENDPOINT + "/delphi/get_delphi_boards",
            headers: API_HEADERS
        }).then( r => {
            setData(r.data);
            setLoading(false);
        }).catch( err => console.log(err) )
    }, [])
    return <Card>
        {loading && <LoadingSpinner/>}
        <CompanyTable data={data}/>
    </Card>
}


const useTableStyles = makeStyles(styles);

function CompanyTable({data}){
    const classes = useTableStyles();
    return <Table
        striped
        hover
        tableHead={["#","Name","Beschreibung","Erstellt","Zuletzt bearbeitet", "Anzahl"]}
        tableData={data.map( list => [list.id, <Link to={`/admin/list/${list.id}`}>{list.name}</Link>, list.description, <span>{moment(list.dateAdded).format("DD.MM.YY hh:mm")} Uhr</span>, <span>{moment(list.lastEdited).format("DD.MM.YY hh:mm")} Uhr</span>, list.items])}
        customCellClasses={[
            classes.center,
            classes.right,
            classes.right
        ]}
        // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
        customClassesForCells={[0, 4, 5]}
        customHeadCellClasses={[
            classes.center,
            classes.right,
            classes.right
        ]}
        // 0 is for classes.center, 4 is for classes.right, 5 is for classes.right
        customHeadClassesForCells={[0, 4, 5]}
    />
}