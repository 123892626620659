import Button from "./CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import React from "react";

export const IncompleteProfileAlert = () => <SweetAlert
    success={ false }
    style={{ display: "block", marginTop: "-100px" }}
    title="Unvollständiges Profil"
    showConfirm={false}
    onConfirm={ () => true } // required prop...
>
    <div>
        <p>Dein Benutzerprofil ist noch nicht vollständig. Bitte klicke hier und vervollständige es. Danach kannst Du diese Seite neu laden und weitermachen.</p>
        <Button color="success" onClick={ () => window.open("/admin/mein-profil")}>
            Profil aktualisieren
        </Button>
    </div>
</SweetAlert>