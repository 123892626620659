import {LOGIN_URL} from "./constants";

export const currencyFormatter = new Intl.NumberFormat("de-DE", {style: 'currency', currency: 'EUR'});

export function validateFee (fee, minEuro, minPercent, maxPercent, nullable, euroOnly) {
    if (nullable && fee === ""){
        return true
    }fee = fee.toUpperCase().replace("€", " EUR").replace("EURO", " EUR");
    fee = fee.toUpperCase().replace("%", " Prozent").replace("PROZENT", " Prozent");
    let amount = "";
    if (fee.includes("EUR")){
        amount = fee.replace("EUR", "");
        amount = amount.replace(".","").replace(",",".");
        if (!isNaN(amount)){
            if (parseFloat(amount) >= minEuro){
                return true
            }
        }
    }
    if (!euroOnly && fee.includes("Prozent")){
        amount = fee.replace("Prozent", "");
        if (!isNaN(amount)){
            amount = parseFloat(amount)
            if (amount >= minPercent && amount <= maxPercent){
                return true
            }
        }
    }
    return false
}

export function redirectOn403(err){
    if(err.response && err.response.status === 403){
        localStorage.removeItem('userToken');
        window.location.href = LOGIN_URL;
    }
}

export function alertOn422(err){
    if(err.response && err.response.status === 422){
        window.alert(err.response.data.error)
    }
}