import React, {useEffect, useState} from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import {makeStyles} from "@material-ui/core/styles";
import {API_ENDPOINT, API_HEADERS} from "../constants";
import axios from "axios";


const useStyles = makeStyles(selectStyles);

export default function UserSelect({onSelect, currentUser}) {
    const classes = useStyles();
    const [users, setUsers] = useState([])

    useEffect(() => {
        axios({
            url: API_ENDPOINT + "/crm/get_all_users",
            headers: API_HEADERS
        }).then( r => {
            setUsers(r.data.map(user => user.email))
        }).catch( err => console.log(err) )
    },[])

    return <div>
        <FormControl
            fullWidth
            className={classes.selectFormControl}
        >
            <Select
                MenuProps={{
                    className: classes.selectMenu
                }}
                classes={{
                    select: classes.select
                }}
                value={currentUser}
                onChange={ e => e && onSelect(e.target.value) }
                inputProps={{
                    name: "genderSelect",
                    id: "genderSelect"
                }}
            >
                {users.sort().map(user => <MenuItem
                    key={user}
                    classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                    }}
                    value={user}
                >
                    {user}
                </MenuItem>)}
            </Select>
        </FormControl>
    </div>
}