import React from 'react';
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import AutoCompleteAsync from "../../components/AutoComplete/AutoCompleteAsync";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {API_ENDPOINT, API_HEADERS} from "../../components/constants";
import axios from "axios";
import Button from "../../components/CustomButtons/Button";
import Snackbars from "components/Snackbar/Snackbar.js";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';


export default class UrlEnricher extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            selectedJob: null,
            gulpiShortName: ""
        }
    }
    componentDidMount() {
        this.getGulpiShortName()
    }
    getGulpiShortName(){
        axios({
            url: API_ENDPOINT + "/crm/get_user_gulp_short_name",
            headers: API_HEADERS
        }).then( r => {
            this.setState({ gulpiShortName: r.data})
        }).catch( err => console.log(err.response.data) )
    }
    render(){
        const {selectedJob, gulpiShortName} = this.state;
        return <div>
            <Card>
                <CardBody>
                    <p>Hiermit kannst Du deine eigenen, ganz individuellen Links für Stellenanzeigen erstellen.</p>
                    <GridContainer>
                        <GridItem md={6}>
                            <AutoCompleteAsync
                                url={`${API_ENDPOINT}/job/search_jobs`}
                                handleChange={selectedJob => this.setState({selectedJob})}
                                labelKey={option => `${option.title} | ${option.creator} | ${option.id}`}
                                name="Stellentitel, SF-ID, Ansprechpartner"
                                optionRenderer={option => <div style={{width: '100%'}}>
                                    <span>{option.title}</span>
                                    <br/>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <small style={{opacity: .5}}>{option.creator}</small>
                                        <small style={{opacity: .5}}>{option.id}</small>
                                        <small style={{opacity: .5}}>{option.datePublished}</small>
                                    </div>
                                </div>}
                            />
                        </GridItem>
                        <GridItem md={6}>
                            {selectedJob && <JobLinks
                                gulpiShortName={gulpiShortName}
                                job={selectedJob}
                            />}
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </div>
    }
}

function JobLinks ({gulpiShortName, job}) {
    const [copied, setCopied] = React.useState("");
    const url = `https://lab.gulp.de/${job.jobType}/${job.id}`;
    const customUrl = url + `?utm_medium=social&utm_campaign=active-sourcing-rec` + "&utm_term=" + gulpiShortName;
    return <div>
        <a href={url} target="_blank"><Button color="primary" style={{width: "100%"}}>
            Standard-Stellenanzeige
        </Button></a>

        <GridContainer>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"LinkedIn".toLowerCase()}` }>
                <Button style={{backgroundColor: "#0077b5", width: "100%"}} onClick={()=>setCopied("LinkedIn")}>
                    Link für LinkedIn kopieren <i className="fab fa-linkedin" style={{marginLeft: "1rem"}}/>
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Xing".toLowerCase()}` }>
                <Button style={{backgroundColor: "#026466", width: "100%"}} onClick={()=>setCopied("Xing")}>
                    Link für Xing kopieren <i className="fab fa-xing" style={{marginLeft: "1rem"}}/>
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Email".toLowerCase()}` }>
                <Button style={{backgroundColor: "#4285f4", width: "100%"}} onClick={()=>setCopied("Email")}>
                    Link für Email kopieren <i className="fas fa-envelope" style={{marginLeft: "1rem"}}/>
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Stepstone".toLowerCase()}` }>
                <Button style={{backgroundColor: "rgb(12,37,119)", width: "100%"}} onClick={()=>setCopied("Stepstone")}>
                    Link für Stepstone kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Monster".toLowerCase()}` }>
                <Button style={{backgroundColor: "rgb(110, 70, 174)", width: "100%"}} onClick={()=>setCopied("Monster")}>
                    Link für Monster kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Facebook".toLowerCase()}` }>
                <Button style={{backgroundColor: "#1877f2", width: "100%"}} onClick={()=>setCopied("Facebook")}>
                    Link für Facebook kopieren <i className="fab fa-facebook" style={{marginLeft: "1rem"}}/>
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Twitter".toLowerCase()}` }>
                <Button style={{backgroundColor: "#1da1f2", width: "100%"}} onClick={()=>setCopied("Twitter")}>
                    Link für Twitter kopieren <i className="fab fa-twitter" style={{marginLeft: "1rem"}}/>
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Indeed".toLowerCase()}` }>
                <Button style={{backgroundColor: "#2557a7", width: "100%"}} onClick={()=>setCopied("Indeed")}>
                    Link für Indeed kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Experteer".toLowerCase()}` }>
                <Button style={{backgroundColor: "#b2cd0e", width: "100%"}} onClick={()=>setCopied("Experteer")}>
                    Link für Experteer kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Talentwunder".toLowerCase()}` }>
                <Button style={{backgroundColor: "#131e30", width: "100%"}} onClick={()=>setCopied("Talentwunder")}>
                    Link für Talentwunder kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"freelance.de".toLowerCase()}` }>
                <Button style={{backgroundColor: "#007d8c", width: "100%"}} onClick={()=>setCopied("Freelance.de")}>
                    Link für Freelance.de kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

            <GridItem md={6}>
                <CopyToClipboard text={customUrl + `&utm_source=${"Freelancermap".toLowerCase()}` }>
                <Button style={{backgroundColor: "#41c0eb", width: "100%"}} onClick={()=>setCopied("Freelancermap")}>
                    Link für Freelancermap kopieren
                </Button>
            </CopyToClipboard>
            </GridItem>

        </GridContainer>

        <Snackbars
            place="tc"
            color="success"
            icon={AssignmentTurnedInIcon}
            message={`Link für ${copied} in Zwischenablage kopiert.`}
            open={copied !== ""}
            closeNotification={()=>setCopied("")}
            close
        />

    </div>
}
