import React, {useState} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Timeline from "components/Timeline/Timeline.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {cardTitle, roseColor} from "assets/jss/material-dashboard-pro-react.js";
import Fingerprint from "@material-ui/icons/Fingerprint";
import {LocalCafe, Nature, Tab, Work} from "@material-ui/icons";
import Search from "@material-ui/icons/Search";
import Gavel from "@material-ui/icons/Gavel";
import {BetaLabExplaination} from "./storyComponents";
import Button from "../../components/CustomButtons/Button";
import {Link} from "react-router-dom";

const styles = {
    cardTitle,
    cardTitleWhite: {
        ...cardTitle,
        color: "#FFFFFF",
        marginTop: "0"
    },
    cardCategoryWhite: {
        margin: "0",
        color: "rgba(255, 255, 255, 0.8)",
        fontSize: ".875rem"
    },
    cardCategory: {
        color: "#999999",
        marginTop: "10px"
    },
    icon: {
        color: "#333333",
        margin: "10px auto 0",
        width: "130px",
        height: "130px",
        border: "1px solid #E5E5E5",
        borderRadius: "50%",
        lineHeight: "174px",
        "& svg": {
            width: "55px",
            height: "55px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            width: "55px",
            fontSize: "55px"
        }
    },
    iconRose: {
        color: roseColor
    },
    marginTop30: {
        marginTop: "30px"
    },
    testimonialIcon: {
        marginTop: "30px",
        "& svg": {
            width: "40px",
            height: "40px"
        }
    },
    cardTestimonialDescription: {
        textAlign: "left",
        fontStyle: "italic",
        color: "#999999"
    },
    avatar: {
        height: 80,
        width: 80,
        borderRadius: 40,
        marginBottom: -45,
        objectFit: "cover",
        boxShadow: "0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
    }
};

const useStyles = makeStyles(styles);

export default function Widgets() {
    const classes = useStyles();
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={7}>
                    {/*<Link to="/admin/tools/url-enricher">
                        <Button color="primary">Links für Stellenanzeigen individualisieren</Button>
                    </Link>*/}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                    <BetaLabExplaination classes={classes}/>
                    <div style={{ textAlign: "center", marginTop: "2rem", fontWeight: 700, opacity: .5 }}>
                        {"Bisherige Ideen und was daraus wurde...".toUpperCase()}
                    </div>
                    <Timeline simple stories={betaLabIdeas} /> {/* FEATURE PIPELINE*/}
                </GridItem>
            </GridContainer>
        </div>
    );
}

const betaLabIdeas = [
    {
        // First story
        inverted: true,
        badgeColor: "success",
        badgeIcon: Search,
        title: "Alternatives Stellenportal",
        titleColor: "success",
        body: (
            <p>
                Die Idee war es, das bestehende GULP Stellenportal neu zu denken und mit einer vereinfachten Benutzeroberfläche, einem anderen Suchalgorithmus und optimierter UX ein besseres Besuchererlebnis und somit letztendlich auch bessere Conversion Rates
                (<em>"wie viele Besucher des Stellenportals letztendlich eine Bewerbung abschicken"</em>) zu erzielen.
            </p>
        ),
        footerTitle: (
            <p>Idee: <strong>BetaLab</strong></p>
        )
    },
    {
        // Second story
        inverted: false,
        badgeColor: "success",
        badgeIcon: Work,
        title: "Überarbeitetes Design der Stellenanzeigen",
        titleColor: "success",
        body: (
            <p>
                In den endlosen Weiten der Marketing-Ideenbibliothek ruhte ein vergessener Schatz, welche Bewerber aus aller Welt auf die Seiten von GULP leiten sollten. Innerhalb kürzester Zeit und mit Cool Running-esker Teamarbeit wurde aus diesen Entwürfen das,
                was wir in aller Bescheidenheit Die Stellenanzeige nennen.
            </p>
        ),
        footerTitle: (
            <p>Idee: <strong>Dorothee Lieberum + Steff Symanek</strong>, Mitwirkende: <strong>Anne Thomas, Jan Rehder, Sven Eckardt</strong></p>
        )
    },
    {
        // Third story
        inverted: true,
        badgeColor: "success",
        badgeIcon: Gavel,
        title: "Dokumenten-Generator aka Was-Infinica-eigentlich-werden-sollte",
        titleColor: "success",
        body: (
            <div>
                <p>
                    "Mit dem Mist können wir nicht mehr leben. Jeder ist genervt! Da muss was anderes her", fluchte ein sonst nicht aus der Ruhe zu bringender Andi Müller an einem regnerischen März-Nachmittag.
                </p>
                <p>Die runde nickte, stimmte Andi demütig und ein wenig schuldbewusst zu. "Wir haben das jetzt lange genug mitgemacht, es ist Zeit für was Neues!", tönt es von Chris Brücker, der nachdenklich an seinem Vollbart zupft, was ihm Jan Scheffler augenscheinlich nachtat.</p>
                <p>
                    "Ich glaube, da könnten wir was mit dem BetaLab entwerfen. Wird mit Sicherheit nicht perfekt, aber besser als Infinica sollten wir schaffen.", räumt Daniela Kluge mit einem verschmitzten Lächeln ein.
                </p>
                <p>Der Rest ist Geschichte...</p>
            </div>
        ),
        footerTitle: (
            <p>Idee: <strong>Darüber werden noch Generationen mutmaßen</strong>, Mitwirkende: <strong>Andi Müller, Chris Brücker, Jan Scheffler, Christian Lenz</strong></p>
        )
    },
    {
        // Third story
        inverted: true,
        badgeColor: "success",
        badgeIcon: Tab,
        title: "Abbildung von Organice FIPs",
        titleColor: "success",
        body: (
            <div>
                <p>
                    Da sich unser Organice-Tool dem Ende des Produktlebenszyklus nähert, macht es für uns wenig Sinn noch Geld zu investieren. Das hat auch eine Limitierung der Lizensen zur Folge.
                </p>
                <p>Unser lieber Andi Müller hat daher den Kollegen Oliver Lange gebeten, doch mal beim BetaLab anzuklopfen, ob's da nicht auch was gibt (vielleicht war Andi mal bei Ratiopharm??). Und siehe da, es gibt was.</p>
                <p>Seither ist 1 Woche vergangen und jetzt gibt es in bester Oprah Winfrey Manier ORGANIZE FÜR ALLEEEE!!!</p>
            </div>
        ),
        footerTitle: (
            <p>Idee: <strong>Andreas Müller, Oliver Lange</strong>, Mitwirkende: <strong>Peter Schultze, Desiree Grabowski</strong></p>
        )
    },
    {
        // Fourth story
        inverted: true,
        badgeColor: "warning",
        badgeIcon: Nature,
        title: "Project Delphi",
        titleColor: "warning",
        body: (
            <div>
                <p>
                    Nichts ist mehr Wert als Daten - und davon haben wir bei GULP sehr viele. Delphi soll Dir dabei helfen noch schneller, noch bessere Kunden zu finden.
                </p>
                <p>
                    Das Projekt ist work in Progress und daher freuen wir uns hier besonders auf Deinen Input!
                </p>
            </div>
        ),
        footerTitle: (
            <p>Idee: <strong>BetaLab</strong>, Mitwirkende: <strong>Christiane Moritz</strong></p>
        )
    },
    {
        // NEXT STORY
        inverted: true,
        badgeColor: "gray",
        badgeIcon: LocalCafe,
        title: "Derzeit arbeiten wir schon auf Hochtouren an...",
        titleColor: "gray",
        body: (
            <div>
                <p>
                    ... ja gut, ganz so einfach ist das doch nicht. Da musst Du Dich noch ein wenig gedulden. Aber schau doch regelmäßig vorbei oder spitze Deine Ohren - wer weiß, was auf den Fluren so gemunkelt wird. The next big thing vielleicht?
                </p>
                <p>Aller Anfang ist klein und manchmal auch ein wenig schwer.</p>
            </div>
        ),
    },
    {
        // NEXT STORY
        inverted: true,
        badgeColor: "primary",
        badgeIcon: Fingerprint,
        title: "Und jetzt bist Du dran!",
        titleColor: "primary",
        body: (
            <div>
                <p>
                    Welche Ideen hast Du? Was treibt Dich rum, hält Dich abends wach, oder beschäftigt Dich morgens im Bad beim... äh... Zähneputzen?
                </p>
                <p>
                    Trau Dich und wende Dich an Deine Kollegen im T&I Vertical mit Ideen, von denen Du glaubst, dass sie uns voranbringen. Wir sind One Gulp und Du bist, nun ja, one of us.
                </p>
            </div>
        )
    }
]


function AskMeAnything(){

}