// export const API_ENDPOINT = "http://127.0.0.1:8000";
export const API_ENDPOINT = "https://betalab-server.gulp.de";

export const LOGIN_URL = API_ENDPOINT + "/login";

export const CLIENT_DOCUMENT_GENERATOR_TYPES = [
    {value: "create-gcs-quotation", label: "Neues GCS Angebot"},
    {value: "create-gcs-pev", label: "Neuer GCS PEV"},
    {value: "create-gss-contract", label: "Neuer GSS PV Vertrag"},
    {value: "create-gch-document", label: "Neues GCH Dokument"},
    {value: "create-gis-document", label: "Neuer GIS Vertrag"},
];

export const APPLICATION_DOCUMENT_GENERATOR_TYPES = [
    {value: "create-gcs-preliminary-contract", label: "Neuer GCS FB Vorvertrag"},
];

export const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const dateRex = /^\d{2}[.]\d{2}[.]\d{4}$/;

export const ReactTrello_i18n_de = {
    "Add another lane": "+ Neue Liste erstellen",
    "Click to add card": "Klicken um Karte hinzuzufügen",
    "Delete lane": "Liste löschen",
    "Lane actions": "Listen-Aktionen",
    "button": {
        "Add lane": "Liste erstellen",
        "Add card": "Karte erstellen",
        "Cancel": "Abbrechen"
    },
    "placeholder": {
        "title": "Titel",
        "description": "Beschreibung",
        "label": "Label"
    }
}

export const TEXTKERNEL_SKILL_CATEGORIES = {
    professional_skills: "Fachkenntnis",
    soft_skills: "Soft Skill",
    lang_skills: "Sprachkenntnis",
    it_skills: "IT Skill",
}

export const API_HEADERS = {
    'Authorization': 'Token ' + localStorage.getItem('userToken')
};
