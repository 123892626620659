import Dashboard from "views/Dashboard/Dashboard.js";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Gavel from "@material-ui/icons/Gavel";
import Wiz_GCS_Quotation from "./views/ContractManagement/GCS_Quotation/wiz_GCS_Quotation";
import Authentication from "./views/auth/Authentication";
import Wiz_GCS_PEV from "./views/ContractManagement/GCS_PEV/wiz_GCS_PEV";
import Wiz_GSS_PermContract from "./views/ContractManagement/GSS_PermContract/wiz_GSS_PermContract";
import ClientDocumentCreator from "./views/ContractManagement/ClientDocumentGenerator";
import Wiz_GCS_PC from "./views/ContractManagement/GCS_PC/wiz_GCS_VV";
import ApplicationDocumentCreator from "./views/ContractManagement/ApplicationDocumentGenerator";
import {Nature, Tab, ViewColumn} from "@material-ui/icons";
import Organice from "./views/Organice";
import UserProfile from "./views/Profile";
import Search from "@material-ui/icons/Search";
import Delphi from "./views/Delphi/Search";
import MyLists from "./views/Delphi/MyLists";
import DelphiBoard from "./views/Delphi/DelphiBoard";
import UrlEnricher from "./views/Tools/UrlEnricher";
import Wiz_CandidateQuestionnaire from "./views/Textkernel/CandidateQuestionnaire/Wiz_CandidateQuestionnaire";
import Wiz_GCH_Document from "./views/ContractManagement/GCH_Document/wiz_GCH_Document";
import Wiz_GIS_Document from "./views/ContractManagement/GIS_Document/wiz_GIS_Document";


const dashRoutes = [
  {
    path: "/authenticate",
    name: "Authenticating",
    component: Authentication,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/client-document-generator",
    name: "Generator für Kunden-Dokumente",
    component: ClientDocumentCreator,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/application-document-generator",
    name: "Generator für Bewerbungs-Dokumente",
    component: ApplicationDocumentCreator,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Vertrags-Center",
    icon: Gavel,
    state: "contractCenterCollapse",
    views: [
      {
        collapse: true,
        name: "Kunden",
        mini: "KD",
        state: "clientDocumentsCollapse",
        views: [
          {
            path: "/create-gcs-quotation",
            name: "GCS Angebot",
            mini: "GCSA",
            component: Wiz_GCS_Quotation,
            layout: "/admin"
          },
          {
            path: "/create-gcs-pev",
            name: "GCS PEV",
            mini: "PEV",
            component: Wiz_GCS_PEV,
            layout: "/admin"
          },
          {
            path: "/create-gss-contract",
            name: "GSS PV Vertrag",
            mini: "PVV",
            component: Wiz_GSS_PermContract,
            layout: "/admin"
          },
          {
            path: "/create-gch-document",
            name: "GCH Dokument",
            mini: "GCH",
            component: Wiz_GCH_Document,
            layout: "/admin"
          },
          {
            path: "/create-gis-document",
            name: "GIS Dokument",
            mini: "GIS",
            component: Wiz_GIS_Document,
            layout: "/admin"
          },
        ]
      },
      {
        collapse: true,
        name: "Kandidaten",
        mini: "BD",
        state: "candidateDocumentsCollapse",
        views: [
          {
            path: "/create-gcs-preliminary-contract",
            name: "GCS Vorvertrag",
            mini: "VV",
            component: Wiz_GCS_PC,
            layout: "/admin"
          },
        ]
      }
    ]
  },
  {
    path: "/organice",
    name: "Organice",
    icon: Tab,
    component: Organice,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Delphi [PREVIEW]",
    icon: Nature,
    state: "delphiCollapse",
    views: [
      {
        path: "/delphi",
        name: "Job-Suche",
        icon: Search,
        component: Delphi,
        layout: "/admin"
      },
      {
        path: "/lists",
        name: "Meine Listen",
        icon: ViewColumn,
        component: MyLists,
        layout: "/admin"
      },
      {
        path: "/list/:id",
        name: "Delphi-Board",
        component: DelphiBoard,
        layout: "/admin",
        invisible: true
      },
    ]
  },
  {
    path: "/mein-profil",
    name: "Mein Profil",
    component: UserProfile,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/tools/url-enricher",
    name: "URL-Enricher",
    component: UrlEnricher,
    layout: "/admin",
    invisible: true
  },
  // BETA FEATURES
  {
    path: "/fragebogen",
    name: "Bewerber-Fragebogen",
    component: Wiz_CandidateQuestionnaire,
    layout: "/admin",
    invisible: true
  },
];
export default dashRoutes;
